import React from 'react';
//import ReactMarkdown from 'react-markdown';
import TextProcessor from './TextProcessor';



// Define an interface for the component props
interface PosProps {
  content?: string;
  
}

const PossiblePosition: React.FC<PosProps> = ({ content}) => {
  return (
    <div className="max-w-m overflow-hidden rounded-xl shadow-lg bg-white animatediv">
      {/* {imageUrl && <img className="w-full" src={imageUrl} alt="Card image" />} */}
      
      <div className="px-10 py-5 text-sm max-lg:text-left text-justify max-lg:px-2">
       
      {content.split('\n\n').map((item, idx) => {
                    return (
                        
                        <span key={idx}>
                          <TextProcessor text = {item} />
                          
                          
                        </span>
                       
                 // eslint-disable-next-line no-irregular-whitespace
                      );
                })}
       
      </div> 
      <p className="px-4 py-4 text-sm font-semibold">If the following roles do not align with the job role you are looking for, then your resume needs to be revamped and certain  additional skills/certifications needs to be added.</p>
    </div>
  );
};


export default PossiblePosition;