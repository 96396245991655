import React from 'react'; //{ useState }



const UploadModal = ({ isOpen, setIsOpen, children }) => {
  // Prevent scrolling on the background when modal is open
  if (isOpen) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'unset';
  }

  if (!isOpen) return null;
  
  return (
    <div
      className="fixed  inset-0 bg-gray-600  bg-opacity-50 flex justify-center items-center"
      onClick={() => setIsOpen(false)}
      style={{ zIndex: 1050 }}  // Tailwind does not support z-index 1050 out of the box
    >
      <div
        className="bg-white rounded-lg shadow-lg w-[50%] overflow-y-auto w-full md:w-1/2"
        onClick={e => e.stopPropagation()}  // Prevents the click from bubbling to the overlay
        style={{ zIndex: 1060 }}  // Tailwind does not support z-index 1060 out of the box
      >
        <div className="flex justify-end items-center bg-black p-1 rounded-md shadow-lg ">
        <button
          className="bg-[#C4455E] text-white font-bold py-2 px-4 rounded hover:bg-blue-700 right-0"
          onClick={() => setIsOpen(false)}
        >
          X
        </button>         
        </div>

<div className='w-full py-8 px-12'>

        {children}
</div>
        
      </div>
    </div>
  );
};

export default UploadModal;