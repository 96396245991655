import React, { useState, ChangeEvent } from 'react';

interface FileUploadProps {
  label: string;
  onFileSelected: (file: File | null) => void;
}

const defaultFileName="example.pdf";;

const FileUpload: React.FC<FileUploadProps> = ({ label, onFileSelected }) => {
  const [fileName, setFileName] = useState<string>(defaultFileName);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFileName(file ? file.name : 'No file chosen');
    onFileSelected(file);
  };

  return (
    <div>
      <h2 className='text-3xl pt-2 font-semibold mb-4 text-gray-800'>Upload your resume</h2>
    <div className="flex items-center container max-w-5xl gap-4 mx-auto p-4 rounded-md bg-secondary shadow-md cursor-pointer">
      <label className="block">
        <span className="sr-only">{label}</span>
        <input type="file" className="block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-violet-50 file:text-violet-700
          hover:file:bg-violet-100
          cursor-pointer" onChange={handleFileChange} />
      </label>
      <div className="text-sm font-medium text-gray-700">
        {fileName}
      </div>
    </div>
    </div>
  );
};

export default FileUpload;
