import UserProfileScreen from "../pages/UserProfileScreen";
// import fullProfile from "../components/ProfileSection/fullProfile";
// import editProfile from "../components/ProfileSection/editProfile";
import EmployeeJobApplicationScreen from "../pages/EmployeeJobApplicationScreen";
import HomeScreen from "../pages/HomeScreen";
import LoginScreen from "../pages/LoginScreen";
import NotFoundScreen from "../pages/NotFoundScreen";
import SigninScreen from "../pages/SigninScreen";
//import Report from '../components/report';
import LoadingPage from "../components/elements/LoadingPage";
import DummyReport from "../components/elements/DummyReport";
import Slideuptext from "../components/elements/dummyElements/LoadingCarouselSlideup";
import Privacy from "../components/HomeScreen/Privacy";
import Careers from "../components/elements/Careers";


interface IRoutesData {
  path: string;
  component: React.FC;
}

export const routes: IRoutesData[] = [
  {
    path: "/",
    component: HomeScreen,
  },
  {
    path: "/jobapplication",
    component: EmployeeJobApplicationScreen,
  },
  {
    path: "/profile",
    component: UserProfileScreen,
  },
  // {
  //   path: "/fullProfile",
  //   component: fullProfile,
  // },
  // {
  //   path: "/editProfile",
  //   component: editProfile,
  // },
  {
    path: "*",
    component: NotFoundScreen,
  },
  {
    path:"/login",
    component:LoginScreen,
  },
  {
    path:"/signup",
    component:SigninScreen,
  },
   {
    path:"/loading_page",
    component:LoadingPage
   },
   {
    path:"/report_new",
    component:DummyReport
   },{
    path:"/su",
    component:Slideuptext
   },
   {
    path:"/sun",
    component:Privacy
    
   },
   {
    path:"/careers",
    component:Careers
   }
];
