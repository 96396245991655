//import { useState } from "react";
//import { Link } from "react-router-dom";
// import { FaUserCircle } from "react-icons/fa";
// import { BsFillChatLeftTextFill } from "react-icons/bs";
//import "./custom.css";
import React, { useState } from "react";
import {  useNavigate  } from "react-router-dom";
import Branding from "../shared/Branding";
import { BiMenu } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import "../../components/shared/custom.css";


const ReportNav = ({setIsOpen}) => {
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
  };

  
    const navigate = useNavigate();
  
  return (
    <div
    className={`pt-8 pb-8  flex flex-wrap pl-28 pr-28 justify-between items-center tracking-wider  max-xl:${
      menu ? "flex-col" : "flex-row"
    } max-xl:px-6`}
  >
    <div className={`max-xl:hidden`}>
      <Branding />
    </div>

    <div className="flex flex-wrap justify-between w-full items-center xl:hidden">
      <Branding />
      
      {menu ? (
        <MdCancel size={30} className={`bg-white`} onClick={handleMenu} />
      ) : (
        <BiMenu size={30} className={`bg-white`} onClick={handleMenu} />
      )}
    </div>

    <div
      className={`flex flex-wrap gap-14 justify-between items-center text-lg xl:text-sm  text-slate-950 max-xl:flex-col max-xl:w-full max-xl:mt-8 max-xl:${
        menu ? "flex-col" : "hidden"
      } `}
    >
      {/* <Link
        to="/"
        onClick={() => handleSetActiveLink("/")}
        className={`relative ${activeLink === "/" ? " text-lightblue" : ""} `}
      >
        <p className="flex items-center">
          Resume
          <FaAngleDown />
        </p>
      </Link> */}
     

      {/* <Link
        to="/login"
        onClick={() => handleSetActiveLink("/login")}
        className={`relative xl:hidden ${
          activeLink === "/login" ? "underline-after text-lightblue" : ""
        }`}
      >
        <p>Login</p>
      </Link>

      <Link
        to="/signup"
        onClick={() => handleSetActiveLink("/signup")}
        className={`relative xl:hidden ${
          activeLink === "/signup" ? "underline-after text-lightblue" : ""
        }`}
      >
        <p>Signup</p>
      </Link> */}

    <div className="flex gap-[1rem]">
      <button type="button" onClick={() => {navigate('/')}} className=" bg-transparent text-[#C4455E] border border-[#C4455E] hover:border  focus:outline-none font-medium  px-6 py-1 text-center lg:text-base xl:text-sm">
                
                Home</button>
              

                <button type="button" onClick={() => setIsOpen(true)} className="text-white bg-[#C4455E] font-medium hover:shadow-lg    px-6 py-1 text-center lg:text-base xl:text-sm">Re Upload</button>
      </div>
    </div>

      
  </div>

  );
};

export default ReportNav;
