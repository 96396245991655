/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
// import StateProvider from "./StateProvider";
// import Feature from "./elements/feature";
import Progress from "./progress";
import { useLocation, useNavigate } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";  //BsArrowDown,

import { useState } from "react";
import PersonalInfo from "./dummyElements/PersonalInfo";
//import RelevantResume from "./dummyElements/RelevantResume";
//import SkillGapAnalysis from "./dummyElements/SkillGapAnalysis";
import CoverLetter from "./dummyElements/CoverLetter";
//import PossiblePosition from "./dummyElements/PossiblePosition";
import FixedResume from "./dummyElements/FixedResume";
import React from "react";
import { useMediaQuery } from "react-responsive";
//import { BiDownArrow, BiDownArrowAlt } from "react-icons/bi";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import ExternalResume from "./dummyElements/ExternalResume";
import ReportNav from "./ReportNav";
import UploadModal from "./UploadModal";
import { PdfUpload } from "../HomeScreen/Pdfupload";
import ImprovementSuggest from "./dummyElements/ImprovementSuggest";
//import InfoHover from "./externalcomps/InfoHover";


interface ApplicantData {
  NameOfApplicant: string;
  MobileNumber: string;
  Email: string;
  City: string;
  State: string | null;
  EducationDetails: boolean;
  SkillSet: boolean;
  AnySoftSkillsPresent: boolean;
  AnyCertificationPresent: boolean;
  AnyWorkExperiencePresent: boolean;
  AnyHobbiesPresent: boolean;
  AnyExtraCurricularPresent: boolean;
}


const DummyReport = () => {
  const location = useLocation();
  const { myData } = location.state || {}; // Fallback to an empty object if state is undefined
  //const [data, setData] = useState({});

  const isSmallScreen = useMediaQuery({ maxWidth: 768 });


  sessionStorage.removeItem('alreadyRefreshed');

  const [selectedComponent, setSelectedComponent] = useState("PersonalInfo");
  // const [heading1,setHeading1]=useState("Some random content");
  // const [heading2,setHeading2]=useState("");

  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  //const location = useLocation();
 // const [path, setPath] = useState(location.pathname);
  

  // console.log(myData, "dummyrep");
  // console.log(myData.trial, "dummyrep20");
  // console.log("dummyrep1", myData.employedetail);
  // console.log("dummyrep2", myData.PossiblePosition);
  // console.log("dummyrep3", myData.RelevantResume);
  // console.log("skill gap", myData.SkillGap);

  // console.log("dummyclean", myData.cleanversion);


  const jsondata = JSON.parse(myData.employedetail);

  const jsonndata2 = cleanKeys(jsondata);


  console.log("cleankeys:-", jsonndata2);




  // console.log("cleankeys1   ", jsonndata2.NameOfApplicant);

 //console.log("cleankeys2   ", jsonndata2.AnyWorkExperiencePresent);


  const handleBackClick = () => {
    // Navigate to a specific path when the back button is clicked
    //console.log("back click")
    navigate('/');
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('hasReloaded');

    window.addEventListener('popstate', handleBackClick);

    // Cleanup the event listener
    // return () => {
    //   window.removeEventListener('popstate', handleBackClick);
    // };
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (comp) => {
    setSelectedComponent(comp);
  }

  // useEffect(() => {
  //   if (selectedComponent === 'FixedResume') {
  //     setHeading1("Resume1");
  //     setHeading2("Fixed Resume");
  //   } 
  //   else if(selectedComponent === 'PersonalInfo'){
  //     setHeading1("Hi <name>,");
  //     setHeading2("I'm AIMO, an AI model trained on vast dataset spanning resumes across diverse job roles. I'll thoroughly analyze your resume, scoring it against key parameters and present my insights in an FAQ format. Equipped with advanced ATS, I'll accurately extract details from your resume for a comprehensive evaluation.");
  //   }
  //   else {
  //     setHeading1("some heading or not or anything fdalkjflke fejal "); // Reset the heading value if not 'FixedResume'
  //     setHeading2(""); // Reset the heading value if not 'FixedResume'
  //   }
  // }, [selectedComponent]);

  const rating = () => {

    let Image = 0;
    let resume_type = 0;
    //let pdf_docs = 0;
    let resume_length = 5;
    let page_num = 0;
    let pdf_size = 5;
    let name = 0;
    let email = 0;
    let phonenum = 0;
    let education = 0;
    let city = 0;
    let skillset = 0;
    let softskill = 0;
    let ExtraCurricular = 0;
    let hobbies = 0
    let certifications = 0;
    let workexp = 0;
    let total = 12;
    // extra curricualr add

    if(jsondata.yoe > 0 && jsonndata2.AnyWorkExperiencePresent){
      //console.log("satisfy1");
      workexp = 70;
      total = 13
    }

    if(jsonndata2.AnyCertificationPresent){
      //console.log("satisfy2");
      certifications = 70
    }

    if(!jsonndata2.AnyHobbiesPresent){
      //console.log("satisfy3");
      hobbies = 35;
    }

    if(!jsonndata2.AnyExtraCurricularPresent){
      //console.log("satisfy4");
      ExtraCurricular = 35;
    }

    if (!myData.image) {
      //console.log("satisfy5");
      Image = 70;
    }

    if (jsonndata2.NameOfApplicant != null) {
      //console.log("satisfy6");
      name = 70;
    }

    if (jsonndata2.Email != null) {
      //console.log("satisfy7");
      email = 70;
    }

    if (jsonndata2.MobileNumber != null) {
      //console.log("satisfy8");
      phonenum = 70;
    }

    if (jsonndata2.EducationDetails) {
      //console.log("satisfy9");
      education = 70;
    }

    if (jsonndata2.City != null) {
      //console.log("satisfy10");
      city = 70;
    }

    if (jsonndata2.SkillSet) {
      //console.log("satisfy11");
      skillset = 35;
    }

    if (jsonndata2.AnySoftSkillsPresent) {
      //console.log("satisfy12");
      softskill = 35;
    }

    // console.log('pagenum    ', myData.image);
    // console.log('resumetype    ', myData.resumetype);
    // console.log('resumelength    ', myData.resumelength);

    const filelength = (myData.filesize) / (1024 * 1024);

    // console.log('filesize    ', roundToTwo(filelength));

    ///singleRowResume   mixedResume
    if (myData.pageno == 1 && myData.yoe <= 1) {
      //console.log("satisfy13");
      page_num = 70
    }

    if (myData.resumetype != "Two Column Resume") {
      //console.log("satisfy14");
      resume_type = 15
    }

    if (myData.resumelength >= 350 && myData.resumelength <= 1000) {
      //console.log("satisfy15");
      resume_length = 70
    }

    if (roundToTwo(filelength) < 20) {
      //console.log("satisfy16");
      pdf_size = 70
    }

    const fillers = ExtraCurricular + hobbies;
    const skills = skillset + softskill;

    //  Columns = 0;
    //pdf_docs = 0;
    //  resume_length = 0;
    //  page_num = 0;
    //  pdf_size = 0;

    
    const other_params = Math.round(Image + name + email + phonenum + education + city + skills + resume_type + resume_length + page_num + pdf_size+fillers + certifications + workexp)/total;
    
    // console.log('rates  ', Math.round(Image + name + email + phonenum + education + city + skillset + softskill + resume_type + resume_length + page_num + pdf_size+ExtraCurricular));

    return Math.round(other_params + resume_type);


  }


  function roundToTwo(num) {
    return Math.round(num * 100) / 100;
  }

  const handleSClick = (comp) => {
    // If the clicked component is already selected, deselect it
    if (selectedComponent === comp) {
      setSelectedComponent(null);
    } else {
      // Otherwise, select the clicked component
      setSelectedComponent(comp);
    }
  };

  function cleanKeys(obj: ApplicantData): ApplicantData {
    const cleanedObject = {};
    Object.keys(obj).forEach(key => {
      // Remove "?" from the key
      const cleanKey = key.replace('?', '');
      // Assign the value from the old key to the new, cleaned key
      cleanedObject[cleanKey] = obj[key];
    });
    return cleanedObject as ApplicantData;
  }

  


  return (
    <>
      <ReportNav setIsOpen={setIsOpen} />
      <UploadModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {(myData.trial >0)?(<div>
          <PdfUpload setIsModalOpen={() => { }} />

        </div>):(
          <p>You have exhausted your trials for today please try again tommorow</p>
        )}
      </UploadModal>

      
      

      {isSmallScreen ? (
        <div className="w-screen ">
          <div className="w-full  sm:w-80 overflow-auto sm:h-full flex flex-col  text-gray-700 bg-gray-100 shadow-md bg-clip-border rounded-xl sm:">
            {/* Progress component */}
            <div className="pt-4 text-white">
              {/* Progress component */}
              {/* Assuming Progress is a component that takes value prop */}
              <Progress value={rating()}  tries = {myData.trial} />

            </div>


            
            <hr className="border-t border-gray-300 my-4" />

            {/* Buttons */}
            <div className="p-6 flex flex-col sm:h-full gap-4 sm:justify-evenly">
              <div
                onClick={() => handleSClick("PersonalInfo")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                  Field & section checks
                  </p>
                </button>
                {selectedComponent === "PersonalInfo" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "PersonalInfo" && (
                <PersonalInfo
                  name={jsonndata2.NameOfApplicant}
                  phoneno={jsonndata2.MobileNumber}
                  email={jsonndata2.Email}
                  city={jsonndata2.City}
                  state={jsonndata2.State}
                  edudetail={jsonndata2.EducationDetails}
                  skillset={(jsonndata2.SkillSet == false || jsonndata2.SkillSet == null) ? false : true}
                  SoftSkillsPresent={(jsonndata2.AnySoftSkillsPresent == false || jsonndata2.AnySoftSkillsPresent == null) ? false : true}
                  AnyCertificationPresent={(jsonndata2.AnyCertificationPresent == false || jsonndata2.AnyCertificationPresent == null) ? false : true}
                  WorkExperiencePresent={(jsonndata2.AnyCertificationPresent == false || jsonndata2.AnyCertificationPresent == null) ? false : true}
                  AnyHobbiesPresent={(jsonndata2.AnyHobbiesPresent == false || jsonndata2.AnyHobbiesPresent == null) ? false : true}
                  AnyExtraCurricularPresent={(jsonndata2.AnyExtraCurricularPresent == false || jsonndata2.AnyExtraCurricularPresent == null) ? false : true}
                />

              )}

              {/* External Feature  */}
              <div
                onClick={() => handleSClick("ExternalFeature")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                  Format & layout checks
                  </p>
                </button>
                {selectedComponent === "ExternalFeature" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "ExternalFeature" && (
                <ExternalResume image={myData.image}
                  page_no={myData.pageno}
                  resume_type={myData.resumetype}
                  resume_length={myData.resumelength}
                  file_size={roundToTwo((myData.filesize) / (1024 * 1024))}
                  yoe={myData.Yoe} />
              )}

                {/*fixed resume */}

                <div
                onClick={() => handleSClick("FixedResume")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                  ATS & Revised Resume 
                  </p>
                </button>
                {selectedComponent === "FixedResume" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "FixedResume" && (
                <FixedResume content={myData.cleanversion} textract={myData.plain_resume} />
              )}


              {/* possible positions */}
              {/* <div
                onClick={() => handleSClick("PossiblePosition")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                  Format & layout checks
                  </p>
                </button>
                {selectedComponent === "PossiblePosition" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "PossiblePosition" && (
                <PossiblePosition content={myData.PossiblePosition} />
              )} */}

              

              {/* relevant resume */}
              {/* <div
                onClick={() => handleSClick("RelevantResume")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                    Relevant Resume
                  </p>
                </button>
                {selectedComponent === "RelevantResume" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "RelevantResume" && (
                <RelevantResume content={myData.RelevantResume} yoe={myData.Yoe}  job_position = {myData.JobRole}/>
              )} */}

              {/* SkillGap  */}

              <div
                onClick={() => handleSClick("SkillGap")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                  Resume Info Analysis
                  </p>
                </button>
                {selectedComponent === "SkillGap" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "SkillGap" && (
                 <ImprovementSuggest possible_position = {myData.PossiblePosition} rel_resume = {myData.RelevantResume}  yoe = {myData.Yoe}  job_pos = {myData.JobRole}  skill_gap = {myData.SkillGap} />
              )}

              {/* FixedResume  */}
              {/* <div
                onClick={() => handleSClick("FixedResume")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider">
                  Fixed Resume  
                  </p>
                </button>
                {selectedComponent === "FixedResume" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>
              {selectedComponent === "FixedResume" && (
                <FixedResume content={myData.cleanversion} />
              )} */}

              {/* CoverLetter  */}
              {myData.JobDesc != "Student" && <div
                onClick={() => handleSClick("CoverLetter")}
                className="flex justify-between items-center py-6 px-4 rounded-lg overflow-hidden shadow-lg bg-white text-black w-90 text-[1.2rem]"
              >
                <button className="flex justify-between items-center">
                  <p className="text-right capitalize tracking-wider text-lg">
                    Cover Letter
                  </p>
                </button>
                {selectedComponent === "CoverLetter" ? (
                  <GoTriangleUp size={24} className="text-CTAbuttonPink" />
                ) : (
                  <GoTriangleDown size={24} className="text-CTAbuttonPink" />
                )}
              </div>}
              {selectedComponent === "CoverLetter" && (
                <CoverLetter content={myData.CoverLetter} />
              )}

              {/* Fixed Resume  */}
              


            </div>
          </div>

          {/* Render the selected component */}

          {/* Repeat for other components... */}
        </div>
      ) : (
        <div className="w-screen sm:h-screen flex flex-col sm:flex-row items-center justify-center  py-8 gap-6 px-6 lg:py-1  lg:px-4">
          {/* Below div for left side options */}
          {/* <div className=" w-full sm:w-80 overflow-auto sm:h-full flex flex-col my-6 text-gray-700 bg-gray-100 shadow-md bg-clip-border rounded-xl sm:"> */}
          <div className=" w-full sm:w-[30%] sm:h-full flex flex-col my-6 text-gray-700 bg-gray-100 shadow-md bg-clip-border rounded-xl xl:text-sm">

            <div className="pt-4 text-white">
            <Progress value={rating()}  tries = {myData.trial} />             
            </div>



            <hr className="border-t border-gray-300 my-2" />
            {/* <div className="p-6 flex flex-col sm:h-full gap-4 sm:justify-evenly"> */}
            <div className="p-6 overflow-y-scroll flex flex-col sm:h-full gap-6 sm:justify-evenly lg:px-4">

              <div className={`flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out w-full
              ${selectedComponent === "PersonalInfo" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`} onClick={() => handleClick("PersonalInfo")}>
                <button
                  
                  className="flex justify-between items-center"
                >
                  <p className="text-left capitalize tracking-wider">
                  Field & section checks
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div>


              <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "ExternalFeature" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`} onClick={() => handleClick("ExternalFeature")}>
                <button
                  
                  className="flex justify-between items-center"
                >
                  <p className="text-left capitalize tracking-wider">
                  Format & layout checks
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div>

              <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "FixedResume" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`} onClick={() => handleClick("FixedResume")}>
                <button
                  className="flex justify-between items-center"
                  
                >
                  <p className="text-left capitalize tracking-wider">
                  ATS & Revised Resume 
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div>



              <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "ImproveSection" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`}  onClick={() => handleClick("ImproveSection")}>
                <button
                  className="flex justify-between items-center"
                 
                >
                  <p className="text-left capitalize tracking-wider">
                  Resume Info Analysis
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div>


              {/* <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "PossiblePosition" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`}  onClick={() => handleClick("PossiblePosition")}>
                <button
                  className="flex justify-between items-center"
                 
                >
                  <p className="text-left capitalize tracking-wider">
                    Improvement Sections
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div> */}


              {/* <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "RelevantResume" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`} onClick={() => handleClick("RelevantResume")}>
                <button
                  
                  className="flex justify-between items-center"
                >
                  <p className="text-left capitalize tracking-wider">
                    Relevant Resume
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div> */}

              {/* <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "SkillGap" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`} onClick={() => handleClick("SkillGap")}>
                <button
                  className="flex justify-between items-center "
                  
                >
                  <p className="text-left capitalize tracking-wider">
                    Improvement Areas
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div> */}

              
              
              { myData.JobDesc != "Student" && <div className={`w-full flex justify-between px-4 py-2 bg-white text-black rounded shadow-lg hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out
              ${selectedComponent === "CoverLetter" ? 'border-2 border-CTAbuttonPink' : 'border'} transition-all duration-300`} onClick={() => handleClick("CoverLetter")}>
                <button
                  className="flex justify-between items-center"
                  
                >
                  <p className="text-left capitalize tracking-wider">
                    Cover Letter  
                  </p>
                </button>
                <BsArrowRight size={30} className="text-CTAbuttonPink" />
              </div>}



            </div>
            <div className="p-6 pt-0"></div>
          </div>
          {/* Below div for right side display */}
          <div className="flex flex-col my-6 text-gray-700 bg-gray-100 shadow-md bg-clip-border rounded-xl  w-full sm:w-70 h-full overflow-auto md:overflow-hidden ">
            {/* <div className="bg-gray-100 text-black p-4  text-2xl flex justify-around"><h2>{heading1}</h2><h2>{heading2}</h2></div> */}

            <div className="p-10 py-10 overflow-auto lg:p-4">
              {selectedComponent === "PersonalInfo" && (
                <PersonalInfo
                  name={jsonndata2.NameOfApplicant}
                  phoneno={jsonndata2.MobileNumber}
                  email={jsonndata2.Email}
                  city={jsonndata2.City}
                  state={jsonndata2.State}
                  edudetail={jsonndata2.EducationDetails}
                  skillset={(jsonndata2.SkillSet == false || jsonndata2.SkillSet == null) ? false : true}
                  SoftSkillsPresent={(jsonndata2.AnySoftSkillsPresent == false || jsonndata2.AnySoftSkillsPresent == null) ? false : true}
                  AnyCertificationPresent={(jsonndata2.AnyCertificationPresent == false || jsonndata2.AnyCertificationPresent == null) ? false : true}
                  WorkExperiencePresent={(jsonndata2.AnyWorkExperiencePresent == false || jsonndata2.AnyWorkExperiencePresent == null) ? false : true}
                  AnyHobbiesPresent={(jsonndata2.AnyHobbiesPresent == false || jsonndata2.AnyHobbiesPresent == null) ? false : true}
                  AnyExtraCurricularPresent={(jsonndata2.AnyExtraCurricularPresent == false || jsonndata2.AnyExtraCurricularPresent == null) ? false : true}
                />
              )}
              {/* {selectedComponent === "PossiblePosition" && (
                <PossiblePosition content={myData.PossiblePosition} />
              )} */}
              {selectedComponent === "ImproveSection" && (
                <ImprovementSuggest possible_position = {myData.PossiblePosition} rel_resume = {myData.RelevantResume}  yoe = {myData.Yoe}  job_pos = {myData.JobRole}  skill_gap = {myData.SkillGap} />
              )}
              {/* {selectedComponent === "RelevantResume" && (
                <RelevantResume content={myData.RelevantResume} yoe={myData.Yoe}  job_position = {myData.JobRole}/>
              )} */}
              {/* {selectedComponent === "SkillGap" && (
                <SkillGapAnalysis content={myData.SkillGap} />
              )} */}
              {selectedComponent === "CoverLetter" && myData.JobDesc != "Student" && (
                <CoverLetter content={myData.CoverLetter} />
              )}
              {selectedComponent === "FixedResume" && (
                <FixedResume content={myData.cleanversion} textract={myData.plain_resume} />
              )}
              {selectedComponent === "ExternalFeature" && (
                <ExternalResume image={myData.image}
                  page_no={myData.pageno}
                  resume_type={myData.resumetype}
                  resume_length={myData.resumelength}
                  file_size={roundToTwo((myData.filesize) / (1024 * 1024))}
                  yoe={myData.Yoe} />
              )}

              <p className="pt-10 text-[0.6rem] text-center">AIMO can make mistakes. Check important info.</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DummyReport;
