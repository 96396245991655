



import React from 'react';
import BorderHeaderBox from '../externalcomps/BorderHeaderBox';


// Define an interface for the component props
interface PersonalProps {
  name?: string;
  phoneno?: string;
  location?: string;
  email?: string;
  city?: string;
  state?: string;
  edudetail?: boolean;
  skillset?: boolean;
  SoftSkillsPresent?: boolean;
  AnyCertificationPresent?: boolean;
  WorkExperiencePresent?: boolean;
  AnyHobbiesPresent? : boolean;
  AnyExtraCurricularPresent? : boolean;
}

const PersonalInfo: React.FC<PersonalProps> = ({ name, phoneno, email, city, state, edudetail, skillset, SoftSkillsPresent,AnyCertificationPresent,WorkExperiencePresent, AnyHobbiesPresent, AnyExtraCurricularPresent  }) => {

  const hashMap = new Map();
  const hashMap1 = new Map();

  // const [fields,  setFields] = useState(true);
  // const [sections,  SetSections] = useState(true);

  //console.log('AnyWorkExperiencePresent  ', WorkExperiencePresent);

  if(name){
    hashMap.set('Name', 'true');
  }
  else{
    hashMap.set('Name', 'false');
  }

  if(phoneno  && email){
    hashMap.set('Contact Details', 'true');
  }
  else{
    hashMap.set('Contact Details', 'false');
  }

  // if(email){
  //   hashMap.set('Email ID', 'true');
  // }
  // else{
  //   hashMap.set('Email ID', 'false');
  // }


  if(city && state){
    hashMap.set('Location', 'true');
  }
  else{
    hashMap.set('Location', 'false');
  }

  // if(state){
  //   hashMap.set('State', 'true');
  // }
  // else{
  //   hashMap.set('State', 'false');
  // }

  if(edudetail){
    hashMap1.set('Education History', 'true');
  }
  else{
    hashMap1.set('Education History', 'false');
  }

  if(skillset && SoftSkillsPresent){
    hashMap1.set('Skill set', 'true');
  }
  else{
    hashMap1.set('Skill set', 'false');
  }

  // if(SoftSkillsPresent){
  //   hashMap.set('Soft Skills', 'true');
  // }
  // else{
  //   hashMap.set('Soft Skills', 'false');
  // }

  if(AnyCertificationPresent){
    hashMap1.set('Certifications', 'true');
  }
  else{
    hashMap1.set('Certifications', 'false');
  }

  if(WorkExperiencePresent){
    hashMap1.set('Work Experience', 'true');
  }
  else{
    hashMap1.set('Work Experience', 'false');
  }

  if(AnyHobbiesPresent){
    hashMap1.set('Hobbies', 'true');
  }
  else{
    hashMap1.set('Hobbies', 'false');
  }

  if(AnyExtraCurricularPresent){
    hashMap1.set('Extracurricular', 'true');
  }
  else{
    hashMap1.set('Extracurricular', 'false');
  }


//  if ( hashMap.get("Name") == "true" &&
//   hashMap.get("Contact Details") == "true" &&
//   hashMap.get("Education details") == "true" &&
//   hashMap.get("Location") == "true" &&
// ){

//     setFields(false);

//   } 


//   if ( hashMap1.get("Certifications") == "true" &&
//   hashMap1.get("Work Experience") == "true" &&
//   hashMap1.get("Hobbies") == "true" &&
//   hashMap1.get("Location") == "true"){

//     SetSections(false);

//   }
  
  // console.log(fields);
  // console.log(sections);

  

  const successEntries = [...hashMap].filter(([ key, value]) => {
    console.log(key);
    return value.includes('true');  // Example condition: values that include 'value1'
  });

  const failEntries = [...hashMap].filter(([ key, value]) => {
    console.log(key);
    return value.includes('false');  // Example condition: values that include 'value1'
  });
  

  const successEntries1 = [...hashMap1].filter(([ key, value]) => {
    console.log(key);
    return value.includes('true');  // Example condition: values that include 'value1'
  });

  const failEntries1 = [...hashMap1].filter(([ key, value]) => {
    console.log('fail ', key);
    return value.includes('false');  // Example condition: values that include 'value1'
  });


  //console.log('hasss  ',successEntries)


  return (
    <div className='px-5'>
      <h1 className=" text-base ">Hello  <strong>{name},</strong></h1>
      <p className="pt-2 pb-1 text-sm text-justify">I'm <strong className="italic">AIMO</strong>, and I've compiled the following report based on a comprehensive analysis of your resume. <br />Please explore all the tabs to view the insights. Additionally,
      I've generated personalized <span className="italic">FAQ's</span> on certain topics for your reference. Take a look at those as well.</p>
      
      <p className="py-1 pb-0 text-sm">From my analysis of your resume, I've extracted the following information. Any missing details may already be included in your resume,
        but I was not able to locate them.</p>
    
        {/* <p className="py-3 text-[1.3rem]">Based on my analysis of your resume, I was able to extract the following information:</p>
     */}
      <div >


      <div className="flex flex-col justify-center items-stretch pt-0 pb-2 mb-5">
     
     
        <div className='flex-1'>
      <BorderHeaderBox title="Fields">
      <div className="flex flex-row max-lg:flex-col pt-3 pb-1 text-sm lg:text-base px-4">
        
      <div className='flex w-full max-lg:flex-col '>
      <div className='py-4 px-4 w-1/4 max-lg:w-full bg-[#C2F9CD] shadow-md bg-clip-border rounded-xl'>
                  {(successEntries.length != 0)?(<><p className="pb-2  w-full text-sm font-semibold text-green-800">Present</p>
          <ul>
            {successEntries.map(([key]) => (
                          <li className=" text-sm  py-[2px] text-green-800" key={key}>{key}</li>
            ))}
          </ul></>):(<p className="pb-2  w-full text-sm font-semibold text-green-800">There are no present fields available</p>)}
        </div>
        
        <div className='w-3/4 ml-4 max-lg:w-full max-lg:ml-0 max-lg:mt-4 px-4 py-4 bg-[#E0E7F6] text-base lg:text-base  shadow-md bg-clip-border rounded-xl '>
       {(failEntries.length != 0)?(<> 
        <h1 className='text-sm pb-2 font-semibold'>Recommendations to add/remove in Resume:</h1>
      {(hashMap.get("Name") == "false") ? (<p className="py-[2px] text-sm text-black" ><strong>Name:</strong> Your name is essential not only for the ATS but also for the hiring manager, ensuring they can identify you easily.</p>):(<p></p>)}
      {hashMap.get("Contact Details") == "false" ? (<p className="py-[2px] text-sm text-black" ><strong>Contact Details:</strong>  Including both your phone number and email address in your resume ensures that the hiring team can reach out to you if you're selected for the role.</p>):(<p></p>)}
      {hashMap.get("Location") == "false" ? (<p className="py-[2px] text-sm text-black" ><strong>Location Details:</strong> It's advisable to include your city and state in your resume, especially for roles specific to certain areas, helping recruiters match you with relevant opportunities.</p>):(<p></p>)}
      </>):(<p className="py-[2px] text-sm text-black">There are no recommendations available for Fields.</p>)}
      </div>  
        
        </div>
        

      </div>
      </BorderHeaderBox>
      </div>
      

      <div className='flex-1'>
      <BorderHeaderBox title="Sections">
      <div className="flex flex-row pt-3 pb-1 text-sm lg:text-base px-4">
        
      <div className='w-full flex max-lg:flex-col'>
                    <div className='py-4  px-4 w-1/4 max-lg:w-full  bg-[#C2F9CD] shadow-md bg-clip-border rounded-xl'>
                  {(successEntries1.length != 0)?(
                    <>
                  <p className="pb-2 w-full text-sm font-semibold text-green-800">Present</p>
                  <ul>
                    {successEntries1.map(([key]) => (
                          <li className=" text-sm  py-[2px] text-green-800" key={key}>{key}</li>
                    ))}
                  </ul>
                  </>):(<p className="pb-2 w-full text-sm font-semibold text-green-800">There are no present sections available</p>)}
        </div>

        <div className='w-3/4 ml-4  px-4 py-4  max-lg:w-full max-lg:ml-0 max-lg:mt-4 bg-[#E0E7F6] text-sm  shadow-md bg-clip-border rounded-xl'>
             
        
                    {
                      (failEntries1.length != 0)?(
                        <>
                        <h1 className='text-sm pb-2 font-semibold'>Recommendations to add/remove in Resume:</h1>
                        {(hashMap1.get("Certifications") == "false") ? (<p className="py-[2px] text-sm text-black" ><strong>Certifications:</strong> While it is not mandatory but including certifications in your resume significantly boosts recruiter confidence in your skills. It's essential to add relevant certifications aligned with the job role you're applying for, as they highlight your expertise and suitability for the position.</p>):(<p></p>)}
                        {hashMap1.get("Education History") == "false" ? (<p className="py-[2px] text-sm text-black" ><strong>Education History:</strong> Providing your education history is crucial. It helps the recruitment manager understand your qualification level, and many roles have specific minimum education criteria that interviewers often verify.</p>):(<p></p>)}
      {(hashMap1.get("Work Experience") == "false") ? (<p className="py-[2px] text-sm text-black" ><strong>Work Experience:</strong>  Relevant experience, whether paid or unpaid, is a critical component of a strong resume. Employers closely evaluate a candidate's work history to understand their practical skills, qualifications and responsibilities handled in the previous roles.</p>):(<p></p>)}
      {(AnyHobbiesPresent) ? (<p className="py-[2px] text-sm text-black" ><strong>Hobbies:</strong> Often considered resume fillers, they're typically included when your resume has fewer than 350-400 words. While they can occasionally serve as icebreakers in interviews, they are frequently disregarded by both ATS and recruitment managers.</p>):(<p></p>)}
      {(!skillset) ? (<p className="py-[2px] text-sm text-black" ><strong>Hard Skills:</strong> Your resume must include your skillset—it's absolutely critical. ATS and recruitment managers prioritize assessing these skills above all else before proceeding with further evaluations.</p>):(<p></p>)}
      {(!SoftSkillsPresent) ? (<p className="py-[2px] text-sm text-black" ><strong>Soft Skills:</strong> Despite often being overlooked, they are an indispensable aspect of your resume. Many roles place a high emphasis on soft skills, and even industry-leading ATS platforms evaluate candidates based on these qualities.</p>):(<p></p>)}
      {(AnyExtraCurricularPresent) ? ( <div className="py-[2px] text-sm text-black"> 
      <p>
        <strong>Extracurricular Activities:</strong> 
        &nbsp;If you believe a particular activity could be relevant to the job, it's worth considering adding it to your resume. However, be aware that it can lead to one of three outcomes:
      </p>
      <ol className='ml-8 list-disc'>
        <li>The interviewer may not pay much attention to it.</li>
        <li>It could serve as an icebreaker for conversation.</li>
        <li>The interviewer may view it negatively, interpreting it as a lack of seriousness about the resume.</li>
      </ol>
    </div>):(<p></p>)}
                        </>
                      ):(
                        <h1 className='text-sm pb-2 font-semibold'>There are no recommendations available for Sections.</h1>
                      )
                    }

      </div>
        
        
        </div>
        

      </div>
      </BorderHeaderBox>
      </div>


    </div>
      
    
  </div>

    
      <p className="py-2 text-sm">From the absent fields, If these details are not present in your resume, please fill them accordingly.</p>
    </div>
  );
};


export default PersonalInfo;
