//import { BsLinkedin, BsFacebook } from "react-icons/bs";
// import Branding from "./Branding";  BsTwitter, 
import { Link } from "react-router-dom";
import React, { useState } from "react";
import PopupModal from "../HomeScreen/PopupModal";
import BrandingFooter from "./BrandingFooter";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const scrolltoabout = () =>{
    const pdfuploaddiv=document.getElementById("aboutdiv");
    if(pdfuploaddiv){
      pdfuploaddiv.scrollIntoView({behavior:"smooth"});
    }
  };


  return (
    <div className="bg-primary p-4 tracking-wider max-lg:py-4">
      <div className=" flex justify-evenly items-center py-24 max-lg:flex-col max-lg:gap-8 max-lg:py-8">
        <section className="flex flex-col gap-5 max-lg:my-12">
          <div className="max-w-xs flex flex-col justify-center items-center gap-5 text-lg text-center xl:text-sm">
            <h1 className="text-3xl font-[700] xl:text-2xl text-white space-x-2">
              {/* I<span className="text-[#FF3B5E]">LOVE</span>RESUME */}
              <BrandingFooter />
            </h1>

            {/* <div className={`max-xl:hidden`}>
                    <BrandingFooter />
                  </div> */}

            <p className="xl:text-sm">
              Find your next career opportunity and connect with like-minded
              individuals.
            </p>
          </div>
          <div className="flex justify-center items-center gap-5">
            {/* <BsTwitter size={24} /> */}
            {/* <BsLinkedin size={24} />
            <BsFacebook size={24} /> */}
          </div>
        </section>
        <section className="flex flex-col gap-5 text-lg justify-center items-center text-center xl:text-sm">
          <h1 className="text-xl font-bold xl:text-md">Help Links</h1>
          <div className="flex flex-col gap-2 ">
            <Link to="/"  onClick={() => scrolltoabout()}>About Us</Link>
            <Link to="/careers" >Careers</Link>
            {/* <Link to="/">Services</Link> */}
            <a className="cursor-pointer" onClick={handleModalOpen}>Privacy policy</a>
            <PopupModal isOpen={isModalOpen} onClose={handleModalClose} />
            {/* <Link to="/">Terms & Condition</Link> */}
          </div>
        </section>
        {/* <section className=" max-w-md flex flex-col justify-center items-center text-center gap-5 text-lg xl:text-sm max-lg:my-12">
          <h1 className="text-xl font-bold xl:text-md">Subscribe Our Newsletter</h1>
          <p>
            Get the freshest job news and articles delivered to your inbox every
            week.
          </p>
          <div className="flex">
            <input
              type="email"
              placeholder="Email Address"
              name="subscriberEmail"
              className="w-full p-2 text-lg text-black focus:outline-none xl:text-sm"
            />
            <div className="bg-background flex p-2">
              <button className="w-full py-1 px-4 bg-CTAbuttonPink">
                Submit
              </button>
            </div>
          </div>
        </section> */}
      </div>
      <p className="text-center text-md text-lightText tracking-wider xl:text-sm">
        © 2024 All Rights Reserved{" "}
      </p>
    </div>
  );
};

export default Footer;
