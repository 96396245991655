// Finall resume 
import React, { useState, useEffect } from "react";
import singleRowResume from './ResumeImages/singleRowResume.png';
import twoColumnResume from './ResumeImages/twoColumnResume.jpeg';
import mixedResume from './ResumeImages/mixedResume.jpeg';

interface ModalProps {
  onClose: (selectedImage: string) => void;
}

const Modal: React.FC<ModalProps> = ({ onClose }) => {
  const [images, setImages] = useState<string[]>([]);
  const [descriptions, setDescriptions] = useState<{ name: string; description: string }[]>([]);
  const [name, setName]  = useState<string>("Two Column Resume");
  const [focusIndex, setFocusIndex] = useState<number>(1); // Initially focusing on the two-column resume

  useEffect(() => {
    setImages([singleRowResume, twoColumnResume, mixedResume]);
    setDescriptions([
      { name: "Single Column Resume", description: "Layout where all content is arranged in one column." },
      { name: "Two Column Resume", description: "Layout where all content is arranged in two or more column for eg: skills, personal details will be in the left column and objective, coursework and achievements in the Right." },
      { name: "Mixed Resume", description: "Here there is no visible line of separation between the content and the content is written in separate Textboxes." }
    ]);
  }, []);

  const handleImageClick = (index: number) => {
    // Swap the image positions
    const newImages = [...images];
    const focusedImage = newImages.splice(index, 1)[0];
    newImages.splice(1, 0, focusedImage);
    setImages(newImages);

    
    // Swap the description positions
    const newDescriptions = [...descriptions];
    const focusedDescription = newDescriptions.splice(index, 1)[0];
    newDescriptions.splice(1, 0, focusedDescription);

    //console.log(focusedDescription.name);

    setName(focusedDescription.name);

    setDescriptions(newDescriptions);

    // Update the focus index to the new position of the focused image
    setFocusIndex(1);
  };

  const handleConfirm = () => {
    /*const selectedImage = images[focusIndex];
    console.log('select image: ', selectedImage);
    onClose(selectedImage);*/
    //console.log('select image: ', name);
    onClose(name);

  };

  return (
    <div className="fixed  inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-black bg-opacity-50 w-full h-full flex justify-center items-center"></div>
      <div className="bg-white rounded-lg py-6 px-4 h-[90%] w-3/5 max-md:w-[95%] max-lg:w-4/5 relative z-10 grid grid-cols-1 justify-center items-center  ">
        <div className="flex flex-col items-center ">

          <h1 className="text-base font-bold text-center ">Select your resume type</h1>
          <p className="text-sm text-gray-600 text-center ">
            Select the one that best matches your resume format
          </p>
        </div>
        <div className="flex justify-center gap-4 h-60 ">
          {images.map((image, index) => (
            <div key={index} onClick={() => handleImageClick(index)} className={index === focusIndex ? "focus flex flex-col justify-center text-center items-center w-56  max-md:w-[100%]" : ""}>
              <img
                src={image}
                alt={`Resume ${index + 1}`}
                className={`h-1/2 object-contain rounded-lg w-fit shadow-lg cursor-pointer transform hover:scale-105 transition-transform duration-300 max-lg:w-fit ${index === focusIndex ? "blur-none h-5/6  " : ""}`}
              />
              {index === focusIndex && (
                <div className="text-center mt-2 text-sm  mb-4">
                  <p className="font-semibold text-sm w-full">{descriptions[index].name}</p>
                  <ul className="list-inside w-full text-xs text-gray-600">
                    {descriptions[index].description.split('.').map((desc, idx) => (
                      <li key={idx}>{desc.trim()}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-center items-center ">
          <button
            onClick={handleConfirm}
            className="bg-green-400 text-white px-4 py-2 rounded-md shadow-md hover:bg-green-600 transition-colors duration-300"
          >
            Confirm
          </button>
          <button
            onClick={() => onClose("")}
            className="bg-white text-silver hover:text-gray-800 ml-4 px-4 py-2 rounded-md shadow-md border border-gray-400 hover:bg-gray-200 hover:border-gray-600 transition-colors duration-300"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;


