import React from 'react';

const CustomMarkdown = ({ text }) => {
    const renderText = () => {
        // Split the text by "**", assuming well-formed input with pairs of "**"
        //const texts = text.replace('**', '*');
        //const textss = texts.replace('*', '**');
        const nbspString = '&nbsp;&nbsp;&nbsp;&nbsp;'
        const texts = text.replace(/\t/g, nbspString);
        const parts = texts.split('**');
        const elements = [];

        // Iterate over the parts and convert even-indexed parts (between pairs of asterisks) to bold
        parts.forEach((part, index) => {
            if (index % 2 === 1) { // Inside double asterisks
                elements.push(<strong key={index}>{part}</strong>);
            } else { // Regular text
                elements.push(part);
            }
        });
        

        return elements;
    };

    return (
        <div className='text-sm w-full'>{renderText()}</div>
    );
};

export default CustomMarkdown;
