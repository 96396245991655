import React from 'react';
import CustomMarkdown from '../externalcomps/MarkdownComponent';
//import BulletList from './BulletList';


const TextProcessor = ({ text }) => {
  // First, split the text into chunks separated by double newlines
  const paragraphs = text.split('\n\n');

  return (
    <div className='w-full flex flex-wrap text-sm'>
      {paragraphs.map((paragraph, idx) => (
        // For each chunk, split by single newline and map to individual lines
        <p key={idx} className='w-full flex flex-wrap text-sm gap-2 max-lg:gap-1'>
          {paragraph.split('\n').map((line, lineIdx) => (
            <React.Fragment key={lineIdx}>
               <CustomMarkdown text={line} /> 
              {/* {lineIdx < paragraph.split('\n').length - 1 && <br />} */}
            </React.Fragment>
          ))}
          <br />
        </p>
      ))}
    </div>
  );
};

export default TextProcessor;
