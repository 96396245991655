import React from "react";

const Optimization = () => {
    return (
        <>

            <div className="pl-36 pr-36 pt-16 pb-16 flex-col items-center w-full max-lg:flex-col max-lg:p-8">
                <div className="text-3xl py-8 font-semibold text-center text-red-500 max-lg:text-2xl"> Resume Optimization Checklist</div>
                <div className="text-black text-sm text-center  max-lg:text-sm">
                    As ATS systems prioritize specific keywords and might not catch sentence structure or grammar issues, it's crucial to cater to both the machine and the hiring manager. Your resume needs to effectively showcase your skills visible to both. That's why we've developed a two-step process:
                    first, we score your resume based on an optimization checklist, and then we rewrite it to enhance engagement and ensure it's error-free.
                </div>


                <div className="grid w-full grid-cols-2 max-lg:grid-cols-1 gap-8 max-lg:gap-4 mt-10">
                <div className="w-full flex flex-col gap-4">

                    <div className="flex flex-col rounded-xl border-2 border-primary bg-white">
                        <div className="py-2 text-sm rounded-lg rounded-b-none tracking-wider text-center text-white uppercase font-bold bg-primary">
                            <h3>Format</h3>
                        </div>
                        <div className="py-2  text-black rounded-b">
                            <ul className="text-sm pt-1 text-left px-4">
                                <li>
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>Image</li>
                                <li>
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>Pagination</li>
                                <li><svg className="w-6 h-6 inline-block mr-2 text-[#008000]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>Column</li>
                                <li>
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>File Format and Size</li>
                                {/* <li><svg className="w-6 h-6 inline-block mr-2 text-gray-800  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                        </svg>File Format and Size</li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-xl border-2 border-primary bg-white">
                        <div className="py-2 text-sm tracking-wider rounded-lg rounded-b-none text-center text-white uppercase font-bold bg-primary">
                            <h3>Resume Sections</h3>
                        </div>
                        <div className="py-2  text-black rounded-b">
                            <ul className="text-sm pt-1 text-left px-4">
                                <li><svg className="w-6 h-6 inline-block mr-2  text-[#008000] " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>Personal Details</li>
                                <li><svg className="w-6 h-6 inline-block mr-2 text-[#008000]  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>Education History</li>
                                <li><svg className="w-6 h-6 inline-block mr-2 text-[#008000]  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>Skillsets</li>
                                <li><svg className="w-6 h-6 inline-block mr-2  text-[#008000] " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>Achievements</li>
                                <li><svg className="w-6 h-6 inline-block mr-2 text-[#008000]  " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                </svg>Hobbies</li>

                            </ul>
                        </div>
                    </div>
                </div>

                    <div className="w-full flex flex-col rounded-xl border-2 border-primary bg-white">
                        <div className="py-2 text-sm tracking-wider rounded-lg rounded-b-none text-center text-white uppercase font-bold bg-primary">
                            <h3>Content Management</h3>
                        </div>
                        <div className="py-2  text-black rounded-b">
                            <ul className="text-sm pt-1 text-left px-4">
                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2  text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Resolving Typos & Grammatical Errors</span>
                                </li>
                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Removing Repetitive & weak Verbs</span>
                                </li>
                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Date format Conversion</span>
                                </li>
                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Passive to Active Voice Conversion</span>
                                </li>
                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Shortening Bullet Points</span>
                                </li>
                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Maintaining Uniformity throughout</span>
                                </li>

                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Having professional tone rather than conversational</span>
                                </li>

                                <li className="py-1 max-lg:py-0">
                                    <svg className="w-6 h-6 inline-block mr-2 text-[#008000] flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 4h3a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3m0 3h6m-6 7 2 2 4-4m-5-9v4h4V3h-4Z" />
                                    </svg>
                                    <span>Focused on creating engaging humanlike Resume</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default Optimization;
