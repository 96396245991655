import RelevantResume from "./RelevantResume";
import PossiblePosition from "./PossiblePosition";
import SkillGapAnalysis from "./SkillGapAnalysis";
import React from "react";

const ImprovementSuggest = ({possible_position, rel_resume, yoe, job_pos, skill_gap}) => {
  
  
  return (
    <div>  
    <h1 className="px-4 py-4 text-md font-semibold">For which type of job position is your resume tailored?</h1>
    <PossiblePosition content = {possible_position}/>
    <br/>
    <br/>
    <h1 className="px-4 py-4 text-md font-semibold">Does your resume stand relevant for a candidate applying for the profile of {job_pos} with an experience of {yoe} years?</h1>
    <RelevantResume content={rel_resume} yoe={yoe}  job_position = {job_pos}/>
    <br/>
    <br/>
    <h1 className="px-4 py-4 text-md font-semibold">Areas for Improvement:</h1>
    <SkillGapAnalysis content = {skill_gap}/>
    </div>
  );
};
export default ImprovementSuggest;
