import React from "react";
import Footer from "./components/shared/Footer";
import Navbar from "./components/shared/Navbar";
import { useLocation } from "react-router-dom";

interface IRootLayoutProps {
  children: React.ReactNode;
}

const RootLayout: React.FC<IRootLayoutProps> = ({ children }) => {

  const location = useLocation();

  // useEffect(() => {
  //     // This function runs when the location (path) changes
  //     console.log('Current path:', location.pathname);

  //     // You can perform actions here based on the new path
  //     // For example, loading data related to the new route
  // }, [location]);


  return (
    <>
      <div className="w-screen flex flex-col flex-wrap text-white">
      {
        location.pathname !== '/login' && location.pathname !== '/signup' && location.pathname !== '/loading_page' && location.pathname !== '/report_new' && <Navbar />
      } 
        
      <main className="min-h-screen text-black">{children}</main>

      {
        location.pathname !== '/login' && location.pathname !== '/signup' && location.pathname !== '/loading_page' && location.pathname !== '/report_new' && <Footer />
      }
      </div>
    </>
  );
};

export default RootLayout;
