import { useState, useEffect } from "react";
import Input from "../../shared/Input";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PersonalinfoSection: React.FC<{ setPersonalInfo: (info: any) => void }> = ({ setPersonalInfo }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [address, setAddress] = useState("");
  const [headline, setHeadline] = useState("");
  const [workStatus, setWorkStatus] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");

  useEffect(() => {
    const personalInfo = {
      name,
      email,
      location,
      phoneNumber,
      dateOfBirth,
      gender,
      currentDateTime,
      address,
      headline,
      workStatus,
      expectedSalary
    };
    setPersonalInfo(personalInfo);
  }, [name, email, location, phoneNumber, dateOfBirth, gender, currentDateTime, address, headline, workStatus, expectedSalary, setPersonalInfo]);

  return (
    <>
      <div className="p-4 bg-gray-100 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold mb-4 text-gray-800">Personal Information</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input elementLable="Name" typeOfInput="text" placeHolder="John Doe" value={name} setValue={setName} />
          <Input elementLable="Email" typeOfInput="email" placeHolder="johndoe@example.com" value={email} setValue={setEmail} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input elementLable="Location" typeOfInput="text" placeHolder="Enter Your Location" value={location} setValue={setLocation} />
          <Input elementLable="Phone Number" typeOfInput="number" placeHolder="9876543210" value={phoneNumber} setValue={setPhoneNumber} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Input elementLable="Date Of Birth" typeOfInput="date" placeHolder="Enter Your DOB" value={dateOfBirth} setValue={setDateOfBirth} />
          <Input elementLable="Gender" typeOfInput="text" placeHolder="Enter Your Gender" value={gender} setValue={setGender} />
          <Input elementLable="Current Date and Time" typeOfInput="text" placeHolder="dd-mm-yy hh-mm" value={currentDateTime} setValue={setCurrentDateTime} />
        </div>
        <div>
          <Input elementLable="Address" placeHolder="5-40/street/location" typeOfInput="text" value={address} setValue={setAddress} />
        </div>
        <div>
          <Input elementLable="Headline" placeHolder="Enter your resume headline" typeOfInput="text" value={headline} setValue={setHeadline} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input elementLable="Work Status" typeOfInput="text" placeHolder="What is Your Work Status" value={workStatus} setValue={setWorkStatus} />
          <Input elementLable="Expected Salary" typeOfInput="number" placeHolder="How much salary you are expecting" value={expectedSalary} setValue={setExpectedSalary} />
        </div>
      </div>
    </>
  );
};

export default PersonalinfoSection;
