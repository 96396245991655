import React from 'react';

const LengthBar = ({ value }) => {
    // Calculate cursor position based on the value
    const cursorPosition = value;

    return (
        <div className="relative w-3/4 h-8 rounded-full overflow-hidden rounded-lg"
        style={{
            background: 'linear-gradient(to right, #f00 0%, #0f0 34%, #ff0 51%)'
        }}>
            <div
                className="absolute top-0 h-8 w-2 bg-black" // Cursor style
                style={{ left: `${cursorPosition}%`, transform: 'translateX(-50%)' }}
            ></div>
        </div>
    );
};

export default LengthBar;
