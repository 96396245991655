
import React, { useState, useEffect } from 'react';
import { AiOutlineCheck } from 'react-icons/ai'; 
import './loading.css'; 

const MultiTextSlideUp = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false); // State to track transition
  const texts =  [ "Verification of Essential Fields and Sections",
  "Refining your Resume", 
  "Identifying Areas for Improvement",
  "Exploring Potential Job Roles",
  "Assessing Resume Fit for the Job Role",
  "Generating ATS Score",
  "Drafting the Report",
  "Reviewing the Report for possible Errors",
  "Generating your Report"];

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentTextIndex === texts.length - 1) {
        setIsTransitioning(true);
        clearInterval(interval); // Stop interval when reaching the last text
      } else {
        setIsTransitioning(true); // Set transitioning state to true before changing text
        setTimeout(() => {
          setCurrentTextIndex((prevIndex) => prevIndex + 1);
          setIsTransitioning(false); // Set transitioning state back to false after changing text
        }, 1000); // Delay changing text for 1 second to show the checkmark
      }
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, [currentTextIndex, texts.length]);

  

  return (
    <div className="multitext-slide-container">
      {texts.map((text, index) => (
        <div key={index} className="multitext-slide-item">
          <div
            className={`multitext-slide-text flex max-lg:flex-col gap-5 justify-center items-center text-CTAbuttonPink ${currentTextIndex === index ? 'active' : ''} ${isTransitioning ? 'text-[#008000]' : ''}`}
            >
            {currentTextIndex === index && (
              <div className={`loading-circle  ${isTransitioning ? 'transitioning' : ''}`}>
                {isTransitioning && text!="Generating your Report" && <AiOutlineCheck className="checkmark" />}
              </div>
            )}
            {text}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiTextSlideUp;