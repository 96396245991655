import React, { useState } from 'react';
import { BsInfoCircle } from 'react-icons/bs'; // Importing Info Icon from React Icons

function InfoHover() {
    const [isHovering, setIsHovering] = useState(false);

    return (
        <div className="flex ml-2 items-center mb-1">
            
            <div className="relative flex items-center">
                <BsInfoCircle size={17}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    className="text-blue-700 cursor-pointer"
                />
                {isHovering && (
                    <div className="absolute z-10 left-full ml-2 w-48 p-2 text-sm text-white bg-black rounded shadow-lg">
                        Your resume score is determined by its format, how relevant the content is, and whether it includes all the necessary information. 
                    </div>
                )}
            </div>
        </div>
    );
}

export default InfoHover;
