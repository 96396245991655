import { useState } from "react";
import React from 'react';
import '../UploadScreen.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
//import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import Progressbar from  '../elements/Progressbar';
import Designations from "../elements/designations";
import { BiLock } from "react-icons/bi";
//import axios from "axios";
//import { PDFPageProxy } from 'pdfjs-dist';



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars

//const url = "https://3xxvpukvn67wzjtkapupqin4d40ebikh.lambda-url.us-east-1.on.aws/";


interface PdfUploadProps{

  setIsModalOpen:(value: boolean) => void;

}


export const PdfUpload: React.FC<PdfUploadProps> = ({setIsModalOpen}) =>{
  const [file, setFile] = useState(null);
  const [filesize, setFileSize] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  //const [successMessage, setSuccessMessage] = useState<string | null>(null);
  // const [resumeFile, setResumeFile] = useState(null);
  const [numPages, setNumPages] = useState(0);
  const [resumelength, setresumelength] = useState(0);
  // const pageNumber =1;
  const [forms, setForms] = useState(true);
  //const [file, setFile] = useState(null);
  const [text, setText] = useState('');
  ///const [word, setWord] = useState<string | null>(null);
  // const [view, setView] = useState(true);
  // const [error, setError] = useState('');

  // const [number, setNumber] = useState(0);
  //const [employedetails, setemployedetails] = useState({});
  //const [cleanresume, setcleanresume] = useState('');
  // const [role, setRole] = useState('Front End Developer');
  // const [selectedValue, setSelectedValue] = useState('');
   //const [generated, setGeneration] = useState(false);
  //const [uploadPercentage, setUploadPercentage] = useState(0);
  //const [progressvisible, setProgressvisible] = useState(false);
  // const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let pagecontent = "";


  // const handleFileChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setFile(selectedFile);
  // };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.size > 20 * 1024 * 1024) {
        setErrorMessage("File size exceeds the 20MB limit.");
      } 
      else if (!droppedFile.type.startsWith("application/pdf")) {
        setErrorMessage("Only PDF files are allowed.");
        return;
      }
      else {
        setFile(droppedFile);
        setErrorMessage(null);
      }
    } else {
      setFile(undefined);
      setErrorMessage(null);
    }


    setFile(droppedFile);
  };
  


  const handleChange = (event) => {
    setText('');
    
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (selectedFile.size > 20 * 1024 * 1024) {
        setErrorMessage("File size exceeds the 20MB limit.");
      } 
      else if (!selectedFile.type.startsWith("application/pdf")) {
        setErrorMessage("Only PDF files are allowed.");
        return;
      }
      else {
        setFile(selectedFile);
        setErrorMessage(null);
      }
    } else {
      setFile(undefined);
      setErrorMessage(null);
    }
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    // console.log('output  ',text);
    // console.log(text.split(" ").length);

    setFileSize(file.size);

    if (!file) {
      setErrorMessage("Please select a file to upload.");
      return;
    }

   

    if (file.size > 20 * 1024 * 1024) {
      setErrorMessage("File size exceeds the 20MB limit.");
      return;
    }
    console.log('word length  ', countWords(text));
    const txtcnt = countWords(text);

    if(txtcnt == 0){
      setErrorMessage("There was some issue while extracting from the uploaded pdf. please check if the pdf you are uploading is the proper resume and upload it again.");
      return;
    }

    if (txtcnt > 2000) {
      //console.log("greater hai")
      setErrorMessage("File size exceeds the words limit.");
      return;
    }

    setForms(false);
    //generate(); commented the generate function here as it has to be called after the form
    
    setFile(undefined);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    //console.log('pagenum  ',numPages );
    setNumPages(numPages);
    //setText(''); // Reset text when a new document is loaded
  };

  const onPageLoadSuccess = (page) => {
    //setView(false);
    ///setText("hello");
    //console.log("change1");
    
    page.getTextContent().then(textContent => {
            ///console.log(text);
      const pageText = textContent.items.map(item => item.str).join(' '); // Join text without newline characters
      //console.log("hola2");
      ///console.log(typeof pageText);
      //console.log("joly  ",pageText);
      setText(prevText => prevText + pageText + '\n\n'); // Append page text with a newline character
      
      pagecontent += pageText + '\n\n';
      ///setText(pagecontent);
      // console.log("hola3");
      // console.log("pagecontent ", pagecontent)
      // console.log('text4   ',text);
      
      const pagewords = pagecontent.split(" ");
      // console.log('wordlength  ', pagewords.length);
      // console.log('text5   ',countWords(pagecontent));
      setresumelength(pagewords.length);




      // console.log("hola12345  ",pagecontent);
      
      // console.log("hola12345  ",pagecontent.length);
      
      // if(pagecontent.length > 6000){
      //   setErrorMessage('number of words should be within the range of 2000 words.')
      // } 

     

    }
    
    );
  
  };


  const countWords = (text) => {
    //// to count number of words
    // Split text into words based on spaces and filter out empty strings
    return text.split(/\s+/).filter(Boolean).length;
  };


  




  return (
    <div id="pdfuploaddiv" className="flex flex-col justify-center items-center w-full bg-white rounded-lg max-lg:p-8">
      {       (forms)? (<form
        onSubmit={onSubmit}
        className="flex w-[90%] flex-col text-center justify-center items-center max-xl:w-full"
      >
      <label
          htmlFor="uploadFile1"
          className="bg-white text-3xl text-center w-full  p-16 flex flex-col items-center justify-center cursor-pointer rounded-3xl border-4 border-dashed border-slate-300  mx-auto font-[sans-serif] m-4 max-lg:text-lg max-lg:p-4 xl:text-xl"
          onDragOver={handleDragOver}
      onDrop={handleDrop}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 mb-6 fill-gray-400"
            viewBox="0 0 24 24"
          >
            <path
              d="M22 13a1 1 0 0 0-1 1v4.213A2.79 2.79 0 0 1 18.213 21H5.787A2.79 2.79 0 0 1 3 18.213V14a1 1 0 0 0-2 0v4.213A4.792 4.792 0 0 0 5.787 23h12.426A4.792 4.792 0 0 0 23 18.213V14a1 1 0 0 0-1-1Z"
              data-original="#000000"
            />
            <path
              d="M6.707 8.707 11 4.414V17a1 1 0 0 0 2 0V4.414l4.293 4.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 1.414 1.414Z"
              data-original="#000000"
            />
          </svg>
          <p className="font-bold ">
            Drag & Drop or <span className="text-CTAbuttonPink">Choose Resume</span>{" "}
            to upload
          </p>
          <input
            type="file"
            id="uploadFile1"
            name="file"
            accept=".pdf,.docx"
            onChange={handleChange}
            className="hidden"
          />
          <p className="text-lg text-gray-400 mt-2 xl:text-md">Only PDF's  are Allowed.</p>
          {(file)?(<p className="text-xxl text-gray-500 mt-5">{file.name}</p>):(<p></p>)}

        <p className={`flex gap-2 mt-6 ${!text?'text-gray-400':'text-green-700'} justify-center items-center texts-sm`}><BiLock/> Privacy Guaranteed</p>
          
        </label>
          
        {errorMessage && (
          <div
            className="bg-red-100 text-red-800 px-4 py-4 rounded m-4"
            role="alert"
          >
            <strong className="font-bold text-base mr-4">Failed!</strong>
            <span className="block text-sm sm:inline max-sm:mt-1">
              {errorMessage}
            </span>
          </div>
        )}

      <div className="pdf-display text-md ">
          {
            // key={`page_${index + 1}`}
            <Document file={file} onLoadSuccess={onDocumentLoadSuccess }>
            {Array.from(new Array(numPages), (el, index) => (
              <div>
              <Page  pageNumber={index + 1} onLoadSuccess={onPageLoadSuccess} />
              <p>{el}</p> 
              </div>
            ))}
          </Document>
          }   
          
        </div>

        
        
        {/* <div className = "py-4 w-full">
        <Progressbar uploadPercentage = {uploadPercentage} isVisible = {progressvisible}/>
        </div> */}
        
        
        

        {(forms && text)?(<button
          type="submit"
          disabled={!file || !!errorMessage}
          className="pt-2 pb-2 pl-10 pr-10 text-lg  bg-CTAbuttonPink rounded-lg text-white tracking-wider border-none outline-none active:from-CTAbuttonPink max-lg:text-[1rem]"
        >
          {file && !errorMessage ? "Upload" : "Select a File"}
        </button>
        
        ):

        (<p></p>)}

        {/*<button
          //onClick={reports}
          className="pt-2 pb-2 pl-6 pr-6 text-base  bg-CTAbuttonPink rounded-lg text-white tracking-wider border-none outline-none active:from-CTAbuttonPink max-lg:text-[1rem]"
        >
          Results
        </button>*/}

        {/* {successMessage && (
          <div
            className="bg-green-100 text-green-800 px-4 py-4 rounded m-4"
            role="alert"
          >
            <strong className="font-bold text-[1.5rem] mr-4">Success!</strong>
            <span className="block text-[1.5rem] sm:inline max-sm:mt-1">
              {successMessage}
            </span>
          </div>
        )} */}

      </form>):<Designations resume = {text} setIsModalOpenProp={setIsModalOpen} pagenum = {numPages} wordlength = {resumelength}  filesize = {filesize} />}
    </div>
  );
}

