export const isValidFile = (file: File) => {
  console.log(file);
  //   if (file) {
  //     const filename = file.name.toLowerCase();
  //     const size = file.size;
  //     if (size > 20 * 1024 * 1024) {
  //       console.error("Please select a file smaller than 20 MB.");
  //       return false;
  //     }
  //     const fileExtension = filename.split(".").pop();
  //     if (fileExtension !== "pdf") {
  //       console.error("Please select a PDF file.");
  //       return false;
  //     }
  //   }

  return true;
};
