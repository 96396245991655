import { FaUserCircle } from "react-icons/fa";
import { FaGears } from "react-icons/fa6";
import { SlBadge } from "react-icons/sl";

import { IAccordianProps } from "./CommonAccordian";

import PersonalinfoSection from "./IndividualSections/PersonalinfoSection";
import SkillsSection from "./IndividualSections/SkillsSections";
import QualificationSection from "./IndividualSections/QualificationSection";
// import JobPreferenceSection from "./IndividualSections/JobPreferenceSection";

export const profileSections: IAccordianProps[] = [
  {
    LogoComponent: FaUserCircle,
    heading: "Personal Information",
    description: "Tell us about yourself",
    Component: PersonalinfoSection,
  },
  {
    LogoComponent: FaGears,
    heading: "Skills",
    description: "Show case your skills",
    Component: SkillsSection,
  },
  {
    LogoComponent: SlBadge,
    heading: "Qualifications",
    description: "Discuss about your qualifications",
    Component: QualificationSection,
  }
  // ,
  // {
  //   LogoComponent: FaUserGear,
  //   heading: "Job Preferences",
  //   description: "Highlight your job details and preferences",
  //   Component: JobPreferenceSection,
  // },
];
