import React from 'react';
import Privacy from './Privacy';
import { CgClose } from 'react-icons/cg';

const PopupModal = ({ isOpen, onClose }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="relative bg-white h-[90%] w-[70%] max-lg:w-[95%] flex flex-col overflow-y-auto text-black p-8 max-lg:p-4 rounded-lg shadow-lg">
            {/* Your component to be rendered in the modal */}
            <Privacy/>
            <CgClose
              className="absolute top-4 right-0 lg:right-4 bg-primary p-2 cursor-pointer text-white rounded"
              onClick={onClose}
              size={24}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PopupModal;
