import axios from "axios";
import React from "react";
import { useState } from "react";
import { BiLock, BiSave } from "react-icons/bi";
//import { InputActionMeta } from "react-select";
import Select from "react-select";


interface JobInfo {
    name: string;
    email: string;
    phone_number:string;
    jobname:string;
    jobtype:string;
}




const Careers = () => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [personalInfo, setPersonalInfo] = useState<JobInfo>({
    name: "",
    email: ""  ,
    phone_number: "",
    jobname:"Front End Developer",
    jobtype:"Full-Time"
    
});

const techJobs = [
    // { value: "Software Developer/Engineer", label: "Software Developer/Engineer" },
    { value: "AI/ML Engineer", label: "Mobile App Developer" },
    { value: "Front End Developer", label: "Front End Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Full-stack Developer", label: "Full-stack Developer" },
]

  

  //const [enable, setenable] = useState(false);

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

 

  

function validatePhoneNumber(input) {
    const phoneNumberPattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return phoneNumberPattern.test(input);
}
  

  const handleInputChange = (field: keyof JobInfo, value: string) => {
    //setSuccessMessage("")
    // console.log('ohh ',value);
    // console.log('field ',field);
    setPersonalInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    
    
  };
  

  
  
  // const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setPersonalInfo((prevState) => ({
  //     ...prevState,
  //     dropdownValue: e.target.value,
  //   }));
  // };

  const reports = () => {
    setErrorMessage(null);
    event.preventDefault(); // This prevents the default form submission behavior
    // console.log('submitted');
    // console.log(personalInfo.name);
    // console.log(personalInfo.email);
    // console.log(personalInfo.phone_number);

    if (!validatePhoneNumber(personalInfo.phone_number)) {
        setErrorMessage("Please enter a valid phone number.");
        return
    } 

    const data = JSON.stringify({
        "email": personalInfo.email,
        "name": personalInfo.name,
        "phonenumber": personalInfo.phone_number,
        "jobrole": personalInfo.jobname,
        "jobtype": personalInfo.jobtype
      });
      
      const config = {
        method: 'post',
        url: 'https://wo2xhu0oe3.execute-api.ap-south-1.amazonaws.com/uat/student',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      if(file){
      axios.request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        //console.log(response.status);
        if(response.status == 200){
            setSuccessMessage("You are registered successfully. We will give you go through your resume and let you know in some days.")
            handleInputChange("name", "")
            handleInputChange("email", "")
            handleInputChange("phone_number", "")
            setFile(null);
        }
      }).catch((error) => {
            console.log(error);
            setErrorMessage("Some Issue occured while submitting the data please try again in sometime.");
      })
    }
    else{
        setErrorMessage("Please upload your resume.");
    }


  }

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.size > 20 * 1024 * 1024) {
        setErrorMessage("File size exceeds the 20MB limit.");
      } 
      else if (!droppedFile.type.startsWith("application/pdf")) {
        setErrorMessage("Only PDF files are allowed.");
        return;
      }
      else {
        setFile(droppedFile);
        setErrorMessage(null);
      }
    } else {
      setFile(undefined);
      setErrorMessage(null);
    }


    setFile(droppedFile);
  };
  

  const handleChange = (event) => {
    
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (selectedFile.size > 20 * 1024 * 1024) {
        setErrorMessage("File size exceeds the 20MB limit.");
      } 
      else if (!selectedFile.type.startsWith("application/pdf")) {
        setErrorMessage("Only PDF files are allowed.");
        return;
      }
      else {
        setFile(selectedFile);
        setErrorMessage(null);
      }
    } else {
      setFile(undefined);
      setErrorMessage(null);
    }
  };

  

  // ------------------------------ *********************************************** -------------------------------

  

 


return (
  <>
    <form className="m-5 md:ml-20 md:mr-20" onSubmit={reports}>
    <h2 className="text-3xl capitalize max-lg:text-2xl font-semibold xl:text-2xl leading-tight text-black mb-5">
                        Join <span className='text-CTAbuttonPink'>Our Team, </span> by registering now !
                    </h2>
  
    <div className="flex flex-col rounded-lg border border border-gray-700 bg-gray-100 gap-y-2 px-4">
       <div className="mt-4 w-full p-4 border border-gray-300 rounded-lg">
      
        <div className="flex flex-col w-full">
        <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
        <label className="py-3 w-full items-center mr-6">
        <input
          type="text"
          name="name"
          value={personalInfo.name}
          placeholder="Name"
          onChange={(e) => handleInputChange("name", e.target.value)}
          className="pl-2 w-full h-10 border border-gray-300 rounded-md focus:outline-none"
          required
        />
        
      </label>
      
      <label className="py-3 w-full items-center mr-6">
        <input
          type="email"
          name="email"
          value={personalInfo.email}
          placeholder="Email"
          onChange={(e) => handleInputChange("email", e.target.value)}
          className="pl-2 w-full h-10 border border-gray-300 rounded-md focus:outline-none"
          required
        />
        {/* <span className="text-sm">Professional</span> */}
      </label>

      <label className="py-3 w-full items-center">
      <input
        type="tel" // Use 'tel' type for semantic purposes and mobile usability
        name="phone"
        value={personalInfo.phone_number}
        onChange={(e) => handleInputChange("phone_number", e.target.value)}
        placeholder="Enter your phone number"
        className={`pl-2 w-full h-10 border ${errorMessage === "Please enter a valid phone number."?"border-red-800":"border-gray-300"} rounded-md focus:outline-none`}
        required
      />
        {/* <span className="text-sm">Professional</span> */}
      </label>


            </div>
        </div>
      </div>

      <div className="mt-4 w-full p-4 border border-gray-300 rounded-lg">
      
      <div className="flex flex-col w-full">
      <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
      <div className="flex flex-col">
      <p className="text-sm font-semibold mb-2">Are you a?</p>
        <div className="flex flex-row items-center">
        <label className="flex items-center mr-6">
        <input
          type="radio"
          name="employmentType"
          value="Full-Time"
          checked={personalInfo.jobtype === "Full-Time"}
          onChange={(e) => handleInputChange("jobtype", e.target.value)}
          className="mr-2 h-6 w-6 text-[#4F46E5] focus:ring-[#4F46E5] border-gray-300 rounded"
        />
        <span className="text-sm">Full-Time</span>
      </label>
      
      <label className="flex items-center">
        <input
          type="radio"
          name="employmentType"
          value="Part-Time"
          checked={personalInfo.jobtype === "Part-Time"}
          onChange={(e) => handleInputChange("jobtype", e.target.value)}
          className="mr-2 h-6 w-6 text-[#4F46E5] focus:ring-[#4F46E5] border-pink-300 rounded"
        />
        <span className="text-sm">Part-Time</span>
      </label>
    </div>
      </div>
    
      <div className="w-1/2 pl-20">
        <label htmlFor="jobProfile" className="text-sm font-semibold mb-2 block">Position You Wish to Apply For</label>
       
        <Select
                                    options={techJobs}
                                    value={{ value: personalInfo.jobname, label: personalInfo.jobname }}
                                    onChange={(selectedOption) => handleInputChange("jobname", selectedOption.value)}
                                    className="w-full h-9 text-sm"
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            borderColor: "#718096",
                                            borderRadius: "0.5rem"
                                        })
                                    }}         />
      </div>


          </div>
      </div>
    </div>



      <div>

        
      <div className="flex flex-col justify-center items-center w-full bg-gray-100 rounded-lg max-lg:p-8">
        <label
          htmlFor="uploadFile1"
          className="bg-white text-3xl text-center w-full  p-16 flex flex-col items-center justify-center cursor-pointer rounded-3xl border-4 border-dashed border-slate-300  mx-auto font-[sans-serif] m-4 max-lg:text-lg max-lg:p-4 xl:text-xl"
          onDragOver={handleDragOver}
      onDrop={handleDrop}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 mb-6 fill-gray-400"
            viewBox="0 0 24 24"
          >
            <path
              d="M22 13a1 1 0 0 0-1 1v4.213A2.79 2.79 0 0 1 18.213 21H5.787A2.79 2.79 0 0 1 3 18.213V14a1 1 0 0 0-2 0v4.213A4.792 4.792 0 0 0 5.787 23h12.426A4.792 4.792 0 0 0 23 18.213V14a1 1 0 0 0-1-1Z"
              data-original="#000000"
            />
            <path
              d="M6.707 8.707 11 4.414V17a1 1 0 0 0 2 0V4.414l4.293 4.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 0 0 1.414 1.414Z"
              data-original="#000000"
            />
          </svg>
          <p className="font-bold ">
            Drag & Drop or <span className="text-CTAbuttonPink">Choose Resume</span>{" "}
            to upload
          </p>
          <input
            type="file"
            id="uploadFile1"
            name="file"
            accept=".pdf,.docx"
            onChange={handleChange}
            className="hidden"
          />
          <p className="text-lg text-gray-400 mt-2 xl:text-md">Only PDF's  are Allowed.</p>
          {(file)?(<p className="text-m md:text-xxl text-gray-500 mt-5">{file.name}</p>):(<p></p>)}

        <p className={`flex gap-2 mt-6 text-gray-400 justify-center items-center texts-sm`}><BiLock/> Privacy Guaranteed</p>
          
        </label>
          
       
      
        
        
        {/* <div className = "py-4 w-full">
        <Progressbar uploadPercentage = {uploadPercentage} isVisible = {progressvisible}/>
        </div> */}
        
    
       
        </div>
      </div>

     
     
      <div className="flex justify-center items-center gap-4">
        <button
          className="p-2 mb-2 font-medium text-white bg-CTAbuttonPink shadow-lg hover:bg-CTAbuttonPink focus:outline-none focus:ring-2 focus:ring-CTAbuttonPink focus:ring-offset-2 focus:ring-offset-pink-200 text-sm tracking-wide max-lg:text-sm max-lg:p-2 flex justify-center items-center text-center gap-2"
          
        >
          Save <BiSave className="inline-block" size={20} />
        </button>
        
      </div>
      
    </div>

     {successMessage && (
          <div
            className="bg-green-100 text-green-800 px-4 py-4 rounded m-4"
            role="alert"
          >
            <strong className="font-bold text-base mr-4">Success!</strong>
            <span className="block text-sm sm:inline max-sm:mt-1">
              {successMessage}
            </span>
          </div>
        )}

    {errorMessage && (
          <div
            className="bg-red-100 text-red-800 px-4 py-4 rounded m-4"
            role="alert"
          >
            <strong className="font-bold text-base mr-4">Failed!</strong>
            <span className="block text-sm sm:inline max-sm:mt-1">
              {errorMessage}
            </span>
          </div>
        )}
    
    </form>

    
    
  </>
);



};

export default Careers;