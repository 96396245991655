import { useState } from "react";
import { Link } from "react-router-dom";
// import { FaUserCircle } from "react-icons/fa";
// import { BsFillChatLeftTextFill } from "react-icons/bs";
import Branding from "./Branding";
import "./custom.css";
import { BiMenu } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
//import { FaAngleDown } from "react-icons/fa";
import React from "react";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState<string>("/");
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleSetActiveLink = (link: string) => {
    setActiveLink(link);
  };

  const scrolltopdfuploadsection = () =>{
    const pdfuploaddiv=document.getElementById("pdfuploaddiv");
    if(pdfuploaddiv){
      pdfuploaddiv.scrollIntoView({behavior:"smooth"});
    }
  };

  const scrolltofaqsection = () =>{
    const pdfuploaddiv=document.getElementById("faqdiv");
    if(pdfuploaddiv){
      pdfuploaddiv.scrollIntoView({behavior:"smooth"});
    }
  };

  return (
    <div
      className={`pt-8 pb-8  flex flex-wrap pl-28 pr-28 justify-between items-center tracking-wider  max-xl:${
        menu ? "flex-col" : "flex-row"
      } max-xl:px-6`}
    >
      <div className={`max-xl:hidden`}>
        <Branding />
      </div>

      <div className="flex flex-wrap justify-between w-full items-center xl:hidden">
        <Branding />

        {menu ? (
          <MdCancel size={30} className={`bg-black`} onClick={handleMenu} />
        ) : (
          <BiMenu size={30} className={`bg-black`} onClick={handleMenu} />
        )}
      </div>

      <div
        className={`flex flex-wrap gap-14 justify-between items-center text-lg xl:text-sm  text-slate-950 max-xl:flex-col max-xl:w-full max-xl:mt-8 max-xl:${
          menu ? "flex-col" : "hidden"
        } `}
      >
        {/* <Link
          to="/"
          onClick={() => handleSetActiveLink("/")}
          className={`relative ${activeLink === "/" ? " text-lightblue" : ""} `}
        >
          <p className="flex items-center">
            Resume
            <FaAngleDown />
          </p>
        </Link> */}
        <Link
          to="/"
          onClick={() => handleSetActiveLink("/")}
          className={`relative ${
            activeLink === "/" ? " text-lightblue  " : ""
          }`}
        >
          <p className="flex items-center">
            Home
            {/* <FaAngleDown /> */}
          </p>
        </Link>
        <Link
          to="/insights"
          onClick={() => handleSetActiveLink("/insights")}
          className={`relative ${
            activeLink === "/insights" ? " text-lightblue" : ""
          }`}
        >
          <p className="flex items-center">
            Blogs
            {/* <FaAngleDown /> */}
          </p>
        </Link>

        <Link
          to="#"
          onClick={() => scrolltofaqsection()}
          className={`relative ${activeLink === "#" ? " text-lightblue" : ""}`}
        >
          <p className="flex items-center">
            FAQ
            {/* <FaAngleDown /> */}
          </p>
        </Link>
        <Link
          to="/careers"
          onClick={() => handleSetActiveLink("/careers")}
          className={`relative ${activeLink === "/careers" ? " text-lightblue" : ""}`}
        >
          <p className="flex items-center">
            Careers
            {/* <FaAngleDown /> */}
          </p>
        </Link>
        {/* <button
            type="button"
            className="text-white bg-[#C4455E] font-medium hover:shadow-lg    px-6 py-1 text-center "
            onClick={scrolltopdfuploadsection}
          >
            Get started
          </button> */}

        {/* <Link
          to="/login"
          onClick={() => handleSetActiveLink("/login")}
          className={`relative xl:hidden ${
            activeLink === "/login" ? "underline-after text-lightblue" : ""
          }`}
        >
          <p>Login</p>
        </Link>

        <Link
          to="/signup"
          onClick={() => handleSetActiveLink("/signup")}
          className={`relative xl:hidden ${
            activeLink === "/signup" ? "underline-after text-lightblue" : ""
          }`}
        >
          <p>Signup</p>
        </Link> */}
        {/* <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse text-lg max-xl:hidden xl:text-sm"> */}
        <div className="flex gap-[1rem]">
          {/* <button
            type="button"
            className=" bg-transparent text-[#C4455E] border border-[#C4455E] hover:border  focus:outline-none font-medium  px-6 py-1 text-center "
          >
            Sign In
          </button> */}

          <button
            type="button"
            className="pt-2 pb-2 pl-8 pr-8 text-md  bg-CTAbuttonPink rounded-lg text-white tracking-wider border-none outline-none active:from-CTAbuttonPink hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out hover:shadow-lg text-center "
            onClick={scrolltopdfuploadsection}
          >
            Get started
          </button>
        </div>
      {/* </div> */}
      </div>
      
    </div>
  );
};

export default Navbar;
