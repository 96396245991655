import React from "react";
import { AiOutlineScan } from "react-icons/ai";
import { RiUploadCloudLine, RiSettingsLine } from "react-icons/ri";

const MyComponent = () => {
  return (
    <div className="pl-36 pr-36 pt-16 pb-16 flex flex-col w-full bg-opacity-10 bg-zinc-500 max-lg:w-full tracking-wide max-lg:p-16 flex-wrap max-lg:text-center" >
      <div className="text-3xl capitalize max-lg:text-2xl font-semibold xl:text-2xl">
        Three <span className="text-CTAbuttonPink">Simple Steps</span>
      </div>
      

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
        <div className="flex flex-col text-justify items-center justify-center bg-white rounded-3xl p-8 shadow-xl hover:shadow-sm ">
          <div className=" mb-2"><RiUploadCloudLine size={30} /></div>

          <h2 className="font-bold  mb-2 text-CTAbuttonPink uppercase text-sm">Upload</h2>
          <p className="text-gray-700 text-sm">
            Ready to get started? Simply click on 'Choose Resume' below to upload your resume. Please note, we currently support only PDF format.
          </p>
        </div>
        <div className="flex flex-col text-justify items-center justify-center bg-white rounded-3xl p-8 shadow-xl hover:shadow-sm ">
          <div className=" mb-2"><AiOutlineScan size={30} /></div>
          <h2 className="font-bold  mb-2 text-CTAbuttonPink uppercase text-sm">Scan</h2>
          <p className="text-gray-700 text-sm">
            <span className="font-semibold">AIMO</span> will run your resume through ATS to get the details, and will create an ATS score based on various parameters.
          </p>
        </div>
        <div className="flex flex-col text-justify items-center justify-center bg-white rounded-3xl p-8 shadow-xl hover:shadow-sm ">
          <div className=" mb-2"><RiSettingsLine size={30} /></div>
          <h2 className="font-bold  mb-2 text-CTAbuttonPink uppercase text-sm">Optimize</h2>
          <p className="text-gray-700 text-sm">
            The model will rewrite your resume and provide personalized tips to enhance your score, along with steps to improve the details in your resume.
          </p>
        </div>
      </div>
    </div>
  );
};


export default MyComponent;
