



import React from 'react';
//import ReactMarkdown from 'react-markdown';
import TextProcessor from './TextProcessor';


// Define an interface for the component props
interface SGProps {
  content?: string;
  
}

const SkillGapAnalysis: React.FC<SGProps> = ({ content}) => {
  return (
    <div className="max-w-m rounded-xl overflow-hidden shadow-lg bg-white animatediv">
      {/* {imageUrl && <img className="w-full" src={imageUrl} alt="Card image" />} */}
      
      <div className="px-10 py-4 text-sm max-lg:text-left text-justify max-lg:px-2">
       
      <TextProcessor text={content} />
      
      </div> 
    </div>
  );
};


export default SkillGapAnalysis;