import { useState } from "react";
import Input from "../../shared/Input";
import React from "react";

const SkillsSection = ({ setSkills }) => {
  const [localSkills, setLocalSkills] = useState([]);

  const addAnotherSkill = () => {
    setLocalSkills((prevSkills) => [...prevSkills, { name: "", stars: 0 }]);
  };

  const handleSkillChange = (index, name, stars) => {
    const updatedSkills = localSkills.map((skill, i) =>
      i === index ? { ...skill, name, stars } : skill
    );
    setLocalSkills(updatedSkills);
    setSkills(updatedSkills); // Update the parent state here
  };

  return (
    <>
      <div className="flex flex-col gap-5 p-4">
        <h1 className="text-3xl font-semibold">Add Skill</h1>
        {localSkills.map((skill, index) => (
          <div key={index} className="flex w-full justify-center items-center gap-10">
            <Input
              elementLable="Skill"
              placeHolder="Javascript"
              typeOfInput="text"
              value={skill.name}
              setValue={(value) => handleSkillChange(index, value, skill.stars)}
            />
            <div className="flex">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`text-xl ${
                    star <= skill.stars ? "text-yellow-400" : "text-gray-300"
                  }`}
                  onClick={() => handleSkillChange(index, skill.name, star)}
                >
                  &#9733;
                </span>
              ))}
            </div>
          </div>
        ))}
        <div className="bg-primary max-w-max text-white py-2 px-6 font-semibold mx-auto">
          <button onClick={addAnotherSkill}>Add Another</button>
        </div>
      </div>
    </>
  );
};

export default SkillsSection;
