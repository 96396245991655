import React, { useState } from 'react';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import singleRowResume from '../elements/ResumeImages/singleRowResume.png';
import twoColumnResume from '../elements/ResumeImages/twoColumnResume.jpeg';
import mixedResume from '../elements/ResumeImages/mixedResume.jpeg';

const Faq = () => {
    const [answersVisibility, setAnswersVisibility] = useState([false, false, false, false]);

    const toggleAnswer = (index) => {
        setAnswersVisibility(prevState => {
            const updatedVisibility = [...prevState];
            updatedVisibility[index] = !updatedVisibility[index];
            return updatedVisibility;
        });
    };

    return (
        <section id="faqdiv" className="py-8 bg-zinc-500 bg-opacity-10 lg:py-16">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-3xl capitalize max-lg:text-2xl font-semibold xl:text-2xl leading-tight text-black ">
                        Explore <span className='text-CTAbuttonPink'>Common </span>Questions
                    </h2>
                </div>
                <div className="max-w-5xl mx-auto mt-8 space-y-4 md:mt-16">
                    {[1, 2, 3, 4, 5, 6, 7,8].map(index => (
                        <div key={index} className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 ">
                            <button type="button" onClick={() => toggleAnswer(index - 1)} className="flex items-center justify-between w-full px-4 py-5 sm:p-6">
                                <span className="flex text-sm font-semibold text-black">
                                    {index === 1 && "What is a good ATS score?"}
                                    {index === 2 && "What is a resume check tool, and how does it work?"}
                                    {index === 3 && "What is a Resume Enhancer?"}
                                    {index === 4 && "What should be the layout of the resume?"}
                                    {index === 5 && "What should be font type and font size used in resume?"}
                                    {index === 6 && "What is quantitative impact and how much important it is"}
                                    {index === 7 && "What should I focus on when checking my resume?"}
                                    {index === 8 && "How can I convert my resume from its current format to a PDF file?"}
                                </span>
                                {answersVisibility[index - 1] ? <FiChevronUp className="w-6 h-6 text-gray-400" /> : <FiChevronDown className="w-6 h-6 text-gray-400" />}
                            </button>
                            <div style={{ display: answersVisibility[index - 1] ? 'block' : 'none' }} className="px-4 pb-5 sm:px-6 sm:pb-6 text-sm">
                                <p>
                                    {index === 1 && "A strong ATS score is determined by factors like fields, sections, format, and layout rather than the specific content. Therefore, a higher score indicates better alignment with ATS requirements."}

                                    {index === 2 && "A resume check is an online tool that scans your resume and provides instructions on how to improve it. Some tools offer resume builders, but we generally suggest candidates write their resumes themselves instead of using AI-based builders. There's often a human touch missing from AI-generated resumes, and recruitment managers who review hundreds of resumes can usually spot an AI-written one immediately. This could create a negative impression that the candidate was too lazy to manually prepare their resume, potentially harming their chances."}

                                    {index === 3 && "A Resume Enhancer is a tool designed to address grammatical issues and typos in a resume, while also enhancing its overall engagement. While some websites offer this service for a fee or may add a watermark to the PDF document, our Resume Enhancer is completely free. Powered by a finely tuned AI model, it is specifically engineered to elevate resumes while maintaining a professional tone and preserving the human touch."}

                                    {index === 4 && (
                                        <div>

                                            "The preferred layout for a resume is either a single-column format or a multiple boxed layout. Resumes with more than one column often perform poorly on ATS systems."

                                            <div className='grid grid-cols-3 gap-12 p-6 max-sm:grid-cols-1 max-lg:gap-4'>
                                                <div className='flex flex-col gap-3 justify-center items-center font-semibold'>

                                                <img
                                                    src={singleRowResume}
                                                    alt={`Resume `}
                                                    className={`object-contain h-full rounded-lg w-fit shadow-lg cursor-pointer `}
                                                />
                                                Single Row Resume
                                                </div>
                                                <div  className='flex flex-col gap-3 justify-center items-center font-semibold'>

                                                <img
                                                    src={twoColumnResume}
                                                    alt={`Resume `}
                                                    className={`object-contain h-full rounded-lg w-fit shadow-lg cursor-pointer `}
                                                />
                                                Two Column Resume
                                                </div>
                                                <div  className='flex flex-col gap-3 justify-center items-center font-semibold'>

                                                <img
                                                    src={mixedResume}
                                                    alt={`Resume `}
                                                    className={`object-contain h-full rounded-lg w-fit shadow-lg cursor-pointer `}
                                                />
                                                Mixed Resume
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {index === 5 && (
                                        <div className='flex flex-col gap-2'>
                                            <p>

                                                Any General font type is acceptable in resume for e.g.:
                                            </p>
                                            <ol className='list-decimal ml-6'>
                                                <li>Times New Roman</li>
                                                <li>Arial</li>
                                                <li>Calibri</li>
                                                <li>Helvetica</li>
                                                <li>Garamond</li>
                                                <li>Georgia</li>
                                            </ol>
                                            <p>

                                                The generally accepted font size for resumes falls within the range of 9.5 to 11.5.
                                            </p>


                                        </div>
                                    )}

                                    {index === 6 && (
                                        <div className='flex flex-col gap-2'>
                                            <p>

                                                Quantifying the impact of your achievements on your resume involves providing specific, measurable results to demonstrate your value in previous roles. This means:
                                            </p>

                                            <ol className='list-decimal ml-6'>
                                                <li>Prioritizing achievements over responsibilities.</li>
                                                <li>Using numbers to illustrate impact, such as percentages or revenue figures.</li>
                                                <li>Beginning each bullet point with an action verb to emphasize your proactive role.</li>
                                            </ol>

                                            <p>

                                                For e.g.: Implemented a new customer relationship management (CRM) system, resulting in a 30% increase in sales productivity and a 25% improvement in customer satisfaction scores.
                                            </p>

                                            <p>
                                                While quantifying your impact is crucial, it's important to maintain a balance. Adding quantitative metrics to every line of your work description can make the resume cumbersome to read and might give the impression of inauthentic. So, striking a balance is essential.

                                            </p>




                                        </div>
                                    )}

                                    {index === 7 && (
                                        <div className='flex flex-col gap-2'>
                                            <p>

                                                We’ve read 1000s of resumes from our users and got the opinions of recruiters on which are the top mistakes people make when building their CV.
                                            </p>
                                            <p>

                                                Here’s a list of the most commonly occurring mistakes on resumes:
                                            </p>
                                            <ol className='list-disc ml-6'>
                                                <li>Avoid cliches and buzzwords.</li>
                                                <li>Don’t lie on your resume.</li>
                                                <li>Edit typos and grammatical errors.</li>
                                                <li>List achievements, not responsibilities.</li>
                                                <li>Keep a clean and organized formatting throughout</li>
                                                <li>Include both your paid and unpaid experience related to the role (the latter is especially important for entry-level positions)</li>
                                                <li>Do not add irrelevant information in your resume. For e.g.: Achieving a 3rd place in dance competition in society when you are applying for software engineering role.</li>
                                            </ol>
                                        </div>
                                    )}

{index === 8 && (
    <div>
        If you have created your resume using Microsoft Word, the software typically includes an option to save or export the document as a PDF file. However, if you encounter any difficulties with this process, you can try uploading your resume to a free online service like <a href="https://www.ilovepdf.com/" target='_blank'><span className='text-CTAbuttonPink'>ilovepdf.com</span></a>, which allows you to convert various file formats, including Word documents, into PDF files at no cost.
    </div>
)}
                                </p>
                            </div> 
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default Faq;
