import React from "react";
import UserProfileSection from "../components/ProfileSection";

const UserProfile = () => {
  return (
    <div>
      <UserProfileSection />
    </div>
  );
};

export default UserProfile;
