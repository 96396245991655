import React from "react";
import { useState } from "react";
import { IconType } from "react-icons";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

export interface IAccordianProps {
  LogoComponent: IconType;
  heading: string;
  description: string;
  Component: React.FC;
}

const CommonAccordian: React.FC<IAccordianProps> = ({
  LogoComponent,
  heading,
  description,
  Component,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  return (
    <>
      <div
        onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}
        className="flex items-center justify-between container max-w-5xl mx-auto p-4 rounded-md bg-secondary shadow-md cursor-pointer"
      >
        <div className="flex items-center justify-center gap-2 px-2">
          {<LogoComponent size={40} color="#0F0C29" />}
          <div className="text-primary">
            <h1 className="font-semibold text-lg">{heading}</h1>
            <p>{description}</p>
          </div>
        </div>
        {isExpanded ? (
          <FaAngleUp size={24} color="#0F0C29" />
        ) : (
          <FaAngleDown size={24} color="#0F0C29" />
        )}
      </div>

      {isExpanded && (
        <div className=" container max-w-5xl mx-auto">
          <Component />
        </div>
      )}

  

    </>
  );
};

export default CommonAccordian;
