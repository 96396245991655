import { Route, Routes } from "react-router-dom";
import { routes } from "./routesData";
import React from "react";

const RouteManager: React.FC = () => {
  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route key={"routes"} path={route.path} element={<route.component />} />
        ))}
      </Routes>
    </>
  );
};

export default RouteManager;
