import React from "react";
import { BiFile } from "react-icons/bi";

const NotFoundScreen: React.FC = () => {
  return (
    <>
      <div className="text-[2.5rem] font-bold w-screen h-screen flex flex-col text-center justify-center items-center capitalize">
        <BiFile size={70} className="text-red-500"/>
        <h1 >Coming Soon</h1>
      </div>
    </>
  );
};

export default NotFoundScreen;
