import React from "react";
import ResumeLogo from '../../images/resumelogo2.png'

const BrandingFooter = () => {
  return (
    <h1>
      {/* I<span className="text-[#FF3B5E]">LOVE</span>RESUME */}

      <img src = {ResumeLogo} className="w-50 h-10"/>
    </h1>
  );
};

export default BrandingFooter;
