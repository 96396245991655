import React from "react";
import Carousel from "./Carousel";
import MultiTextSlideUp from "./MultiTextSlideUp";
import "./loading.css"

const LoadingCarouselSlideup = () => {
  return (
    <>
      <div className="w-screen h-screen overflow-hidden flex max-md:flex-col justify-center items-center text-center gap-5">
        <div className="w-1/3 max-md:w-full">
          <Carousel />
        </div>
        <div className="w-1/2 ">
          <MultiTextSlideUp/>
        </div>
      </div>
    </>
  );
};
export default LoadingCarouselSlideup;
