import React from 'react';

const BorderHeaderBox = ({title, children}) => {
  return (
    <div className="max-w-m relative rounded-2xl border-2 border-black bg-white p-3 mt-8">
      <div className="h-7 absolute top-0 left-4 -translate-y-1/2 border-2 border-black bg-white px-3 text-black">
        <strong className='text-base'>{title}</strong>
      </div>
      {children}
    </div>
  );
};

export default BorderHeaderBox;
