import React from 'react';

// Define the data for the table rows
// const tableData = [
//     { years: "0 - 2.5", maxPages: "1-1.5", maxWords: "500" },
//     { years: "2.5 - 5", maxPages: "1-2", maxWords: "700" },
//     { years: "5-10", maxPages: "1-3", maxWords: "900" },
//     { years: "10+", maxPages: "1-3.5", maxWords: "1100" }
// ];

const TableComponent = ({tableData}) => {
    return (
        <div className="overflow-hidden overflow-x-auto border border-gray-200 rounded">
            <table className="min-w-full text-sm divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left font-medium text-gray-900">Years of Experience</th>
                        <th scope="col" className="px-6 py-3 text-left font-medium text-gray-900">Max No. of Pages</th>
                        <th scope="col" className="px-6 py-3 text-left font-medium text-gray-900">Max No. of words</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {tableData.map((row, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4">{row.years}</td>
                            <td className="px-6 py-4">{row.maxPages}</td>
                            <td className="px-6 py-4">{row.maxWords}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
