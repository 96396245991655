
import React, { useState, useEffect } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import InfoHover from './externalcomps/InfoHover';


export default function Progress({value, tries}) {
    const [progress, setProgress] = useState(0); // Initial progress at 0%



    const slowAnimationStyles = buildStyles({
        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 8, // Adjust for desired animation speed
    
        // Colors (customize as needed)
        pathColor: (value <= 35)?'rgba(255, 0, 0, 0.8)':(value > 35 && value < 60)?'rgba(255, 255, 0, 0.75)':'rgba(0, 255, 0, 0.25)',// Adjust opacity for lighter color
        textColor: '#FBBC05',
        // trailColor: '#FBBC05',
        backgroundColor: '#0F0C29',
        strokeLinecap: 'round',
        
    });
    

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(value); // Update progress to 66% after a delay
        }, 1500); // Delay in milliseconds (adjust for desired speed)

        return () => clearTimeout(timer); // Cleanup function to avoid memory leaks
    }, [value]);

    return (
        <div className='w-full h-full flex items-center justify-center flex-col text-black'>
            <div style={{ width: 130, height: 130 }}>
                <CircularProgressbar
                    value={progress}
                    text={progress+"%"}
                    backgroundPadding={6}
                    styles={slowAnimationStyles}
                    background={true}
                    className='h-full'
                />
                
            </div>
            <div className='flex flex-row mt-3 '>
                <h2 className='text-base font-bold tracking-wide uppercase p-0.5'>Resume Score</h2> 
                <InfoHover/>
            </div>

            <div className='mt-2 '>
                <h2 className='text-base tracking-wide'>Trials left for a day: {tries}</h2>
            </div>
            
        </div>

    );
}
