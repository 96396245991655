import { useState } from "react";
import Input from "../../shared/Input";
import React from "react";

const QualificationSection = ({ setQualificationInfo }) => {
  const [localQualificationInfo, setLocalQualificationInfo] = useState({
    jobTitle: "",
    company: "",
    levelOfEducation: "",
    fieldOfStudy: "",
  });

  const handleInputChange = (field, value) => {
    setLocalQualificationInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setQualificationInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <>
      <div className="flex flex-col gap-5 p-4">
        <h1 className="text-3xl font-semibold">Add Your Recent Work Experience</h1>
        <div className="flex w-full justify-between gap-16">
          <Input
            elementLable="Job Title"
            placeHolder="Full Stack Developer"
            typeOfInput="text"
            value={localQualificationInfo.jobTitle}
            setValue={(value) => handleInputChange("jobTitle", value)}
          />
          <Input
            elementLable="Company"
            placeHolder="Apnabot"
            typeOfInput="text"
            value={localQualificationInfo.company}
            setValue={(value) => handleInputChange("company", value)}
          />
        </div>
        <h1 className="text-3xl font-semibold">Highest Level Of Education</h1>
        <div className="flex w-full justify-between gap-16">
          <Input
            elementLable="Level Of Education"
            placeHolder="Graduation"
            typeOfInput="text"
            value={localQualificationInfo.levelOfEducation}
            setValue={(value) => handleInputChange("levelOfEducation", value)}
          />
          <Input
            elementLable="Field Of Study"
            placeHolder="Computer Science"
            typeOfInput="text"
            value={localQualificationInfo.fieldOfStudy}
            setValue={(value) => handleInputChange("fieldOfStudy", value)}
          />
        </div>
      </div>
    </>
  );
};

export default QualificationSection;
