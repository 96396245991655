import React from "react";
import ResumeLogo from '../../images/resumelogos.png'

const Branding = () => {
  return (
    <h1 className="text-3xl font-bold  text-[#0F0C29] space-x-2 xl:text-2xl">
      {/* I<span className="text-[#FF3B5E]">LOVE</span>RESUME */}

      <img src = {ResumeLogo} className="w-50 h-10"/>
    </h1>
  );
};

export default Branding;
