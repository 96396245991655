import React from "react";
import JobApplication from "../components/JobApplication";

const EmployeeJobApplicationScreen = () => {
  return (
    <>
      <div>
        <JobApplication />
      </div>
    </>
  );
};

export default EmployeeJobApplicationScreen;
