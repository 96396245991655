import React from 'react';

const Privacy = () => {
    return (
        <div className="w-full p-12 text-sm max-sm:p-1 max-lg:p-2">
            <div className='w-full text-center flex justify-center flex-wrap'>

                <h1 className="text-2xl font-bold mb-8 mt-4">PRIVACY NOTICE</h1>
            </div>
            <div className='max-lg:text-left text-justify'>

                <p>Last updated April 30, 2024</p>

                <div className="mt-8 w-full">
                    <div>
                        This privacy notice for ILOVERESUME (doing business as ILOVERESUME) ('we', 'us', or
                        'our'), describes how and why we might collect, store, use, and/or share ('process')
                        your information when you use our services ('Services'), such as when you:
                        <ol className='list-disc pl-6'>
                            <li>

                                Visit our website at http://www.iloveresume.com, or any website of ours
                                that links to this privacy notice
                            </li>
                            <li>

                                Engage with us in other related ways, including any sales, marketing, or
                                Events
                            </li>
                        </ol>

                        <p className='my-4 font-bold'>

                            Questions or concerns?
                        </p>
                        Reading this privacy notice will help you understand your privacy rights and choices.
                        If you do not agree with our policies and practices, please do not use our Services.

                        <p className='my-2 mt-8 font-bold uppercase'>

                            SUMMARY OF KEY POINTS
                        </p>
                        This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.

                        <div className='flex flex-col my-1'>

                            <strong>

                                What personal information do we process?
                            </strong>
                            When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and features you use.
                        </div>

                        <div className='flex flex-col my-1'>

                            <strong>

                                Do we process any sensitive personal information?
                            </strong>
                            We may process sensitive personal information when necessary, with your consent or as otherwise permitted by applicable law.
                        </div>

                        <div className='flex flex-col my-1'><strong>

                            Do we receive any information from third parties?
                        </strong>

                            We do not receive any information from third parties.
                        </div>

                        <div className='flex flex-col my-1' >
                            <strong>

                                How do we process your information?
                            </strong>
                            We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.

                        </div>

                        <div className='flex flex-col my-1'>
                            <strong>

                                In what situations and with which types of parties do we share personal information?
                            </strong>
                            We may share information in specific situations and with specific categories of third parties.
                        </div>

                        <div className='flex flex-col my-1'>
                            <strong>

                                How do we keep your information safe?
                            </strong>
                            We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
                        </div>

                        <div className='flex flex-col my-1'>
                            <strong>

                                What are your rights?
                            </strong>
                            Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.
                        </div>

                        <div className='flex flex-col my-1 mb-4'>
                            <strong>

                                How do you exercise your rights?
                            </strong>
                            The easiest way to exercise your rights is by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                        </div>
                        Want to learn more about what we do with any information we collect? Review the privacy notice in full.


                    </div>
                    <h2 className="text-sm font-bold my-4 mt-8">TABLE OF CONTENTS</h2>
                    <ul className="list-decimal pl-6">
                        <li><a href="#info-collect">What information do we collect?</a></li>
                        <li><a href="#info-process">How do we process your information?</a></li>
                        <li><a href="#info-share">When and with whom do we share your personal information?</a></li>
                        <li><a href="#cookies">Do we use cookies and other tracking technologies?</a></li>
                        <li><a href="#info-keep">How long do we keep your information?</a></li>
                        <li><a href="#info-safe">How do we keep your information safe?</a></li>
                        <li><a href="#info-minors">Do we collect information from minors?</a></li>
                        <li><a href="#privacy-rights">What are your privacy rights?</a></li>
                        <li><a href="#dnt">Controls for Do-Not-Track features</a></li>
                        <li><a href="#update-notice">Do we make updates to this notice?</a></li>
                        <li><a href="#contact-us">How can you contact us about this notice?</a></li>
                        <li><a href="#review-data">How can you review, update, or delete the data we collect from you?</a></li>
                    </ul>
                </div>

                <div id="info-collect" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">1. WHAT INFORMATION DO WE COLLECT?</h2>
                    <p>

                        Personal Information Provided by You.The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and features you use. The personal information we collect may include the following: names, phone numbers, email addresses, job titles, location, designation.
                        <p>

                            <strong>Sensitive Information. </strong>

                            When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information: resume data.
                        </p>

                        <p>

                            <strong>Application Data. </strong>

                            If you use our application(s), we also may collect the following information if you choose to provide us with access or permission
                        </p>
                        <p>

                            <strong>Geolocation Information.</strong>
                            We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings. This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                        </p>
                        <p>

                            <strong>Information automatically collected:</strong>
                            We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
                        </p>

                        Like many businesses, we also collect information through cookies and similar technologies.
                        The information we collect includes:

                        <ol className='list-disc ml-6'>
                            <li>

                                Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                            </li>
                            <li>

                                Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                            </li>
                            <li>

                                Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
                            </li>
                        </ol>

                    </p>
                </div>

                <div id="info-process" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                    <p>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                        <p>
                            <strong>To send you marketing and promotional communications. </strong>
                            We may process the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see 'WHAT ARE YOUR PRIVACY RIGHTS?
                        </p>
                        <p>
                            <strong>Provide data to 3rd party organizations.</strong>
                            We would be providing Location, Mobile Number and name details to verified by our team, 3rd party service providers.</p>
                    </p>
                </div>

                <div id="info-share" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                    <p>
                        <strong>Vendors, Consultants, and Other Third-Party Service Providers: </strong> We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. The categories of third parties we may share personal information with are as follows: Ad Networks
                        We also may need to share your personal information in the following situations:
                        <p>

                            <strong>Business Transfers.</strong>  We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </p>
                        <p>

                            <strong>When we use Google Analytics.</strong>  We may share your information with Google Analytics to track and analyse the use of the Services. To opt out of being tracked by Google Analytics across the Services, visit https://tools.google.com/dlpage/gaoptout.
                            For more information on the privacy practices of Google, please visit the Google Privacy & Terms page.
                        </p>

                    </p>
                </div>

                <div id="cookies" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice</p>
                </div>

                <div id="info-keep" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 90 days.
                        <p>
                            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

                        </p>
                    </p>
                </div>

                <div id="info-safe" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                    <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
                </div>

                <div id="info-minors" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data, we may have collected from children under age 18, please contact us at <span className='underline'>Team@iloveresume.com</span> .</p>
                </div>

                <div id="privacy-rights" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                    <p>
                        <p>

                            <strong>Withdrawing your consent:</strong>  If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                        </p>
                        <p>

                            <strong>Opting out of marketing and promotional communications:</strong> You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
                        </p>
                        <p>

                            <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
                        </p>
                    </p>
                </div>

                <div id="dnt" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
                </div>

                <div id="update-notice" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                    <p>We may update this privacy policy from time to time. The Updated Version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we won’t be notifying you as there is no registration required to use the website. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
                </div>

                <div id="contact-us" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                    <p> If you have questions or comments about this notice, you may email us at <span className='underline'>Team@iloveresume.com</span> </p>
                </div>

                <div id="review-data" className="mt-8">
                    <h2 className="text-sm font-bold mb-4">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                    <p>You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, Please send us a mail on <span className='underline'>Team@iloveresume.com</span> </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
