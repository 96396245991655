import React from "react";

interface IinputProps {
  typeOfInput: string;
  placeHolder: string;
  elementLable: string;
  value: string;
  setValue: (value: string) => void;
  className?: string;
}
const Input: React.FC<IinputProps> = ({
  typeOfInput,
  elementLable,
  placeHolder,
  value,
  setValue,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="flex flex-col border border-gray-400 rounded-md w-full text-md p-1">
      <label htmlFor={elementLable} className="pl-2">
        {elementLable} <span className="text-red-500">*</span>
      </label>
      <input
        name={elementLable}
        type={typeOfInput}
        placeholder={placeHolder}
        className="p-2 border rounded-md border-none focus:outline-none"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default Input;
