



import React from 'react';
//import ReactMarkdown from 'react-markdown';



// Define an interface for the component props
interface CLprop {
  content?: string;
  
}

const CoverLetter: React.FC<CLprop> = ({ content}) => {
  return (
    <div className="max-w-m rounded overflow-hidden shadow-lg bg-white animatediv">
      {/* {imageUrl && <img className="w-full" src={imageUrl} alt="Card image" />} */}
      
      <div className="px-10 py-10 text-sm max-lg:text-left text-justify max-lg:px-2">
       

       {content.split('\n').map((item, idx) => {
                    return (
                        
                        <span key={idx}>
                          
                          {item}
                       
                          <br/>
                        </span>
                       
                 // eslint-disable-next-line no-irregular-whitespace
                      );
                })}
      
      </div> 
    </div>
  );
};


export default CoverLetter;