import React from "react";
import { FaEdit } from "react-icons/fa";

const UserProfileBreif = () => {
  return (
    <>
      <div className="container max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex gap-10 items-center justify-center">
          <div className="flex items-center justify-center p-6 bg-primary text-white rounded-full text-xl font-semibold">
            DS
          </div>
          <div>
            <p>Dhanasai Tholeti</p>
            <p>FullStack Developer</p>
            <p>example@gmail.com</p>
            <p>Dhanasai Tholeti</p>
          </div>
        </div>

        <div className="flex items-center justify-between gap-2 border border-dashed border-primary py-2 px-6 rounded-md">
          <FaEdit size={20} />
          <button className="font-semibold text-lg">Edit Profile</button>
        </div>
      </div>
    </>
  );
};

export default UserProfileBreif;
