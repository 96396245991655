import {
  ChangeEvent,
  Dispatch,
  DragEvent,
  SetStateAction,
  useRef,
} from "react";
import { inputBorderStyles, inputStyles } from "../JobApplication";
import { isValidFile } from "../../utils/isValidFile";
import React from "react";

interface IDragAndDropInputProps {
  selectedFIle: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
}

const DragAndDropInput: React.FC<IDragAndDropInputProps> = ({
  selectedFIle,
  setSelectedFile,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) isValidFile(file) && setSelectedFile(file);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) isValidFile(file) && setSelectedFile(file);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleBrowseFilesClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger file input click
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className={`${inputBorderStyles} border-2 border-dashed ${inputStyles} overflow-hidden max-w-2xl`}
    >
      {selectedFIle ? (
        <p className="text-gray-600 font-semibold">{selectedFIle?.name}</p>
      ) : (
        <p className="text-gray-600 font-semibold">
          Drag & Drop or{" "}
          <span
            className="text-black cursor-pointer"
            onClick={handleBrowseFilesClick}
          >
            Browser Files
          </span>
        </p>
      )}
      <input
        id="file"
        type="file"
        name="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  );
};

export default DragAndDropInput;
