// import { AiOutlineScan } from "react-icons/ai";
// import triangle from "../../images/triangle.svg";
// import bghero from "../../images/bghero.png";
// import Image1 from "../../images/Image1.png";
// import Image2 from "../../images/frontpage.png";
import Facebook from "../../images/heroicon/Facebook.png";
//import Amazon from "../../images/heroicon/Amazon.png";
import Google from "../../images/heroicon/Google.png";
import Tesla from "../../images/heroicon/Tesla.png";
import Spotify from "../../images/heroicon/Spotify.png";
//import videoFile from "../../images/herovid.mov";
import FlipCarousel from "../elements/FlipCarousel";
//import { BsAmazon } from "react-icons/bs";
import React from "react";

const Hero = () => {
  const scrolltopdfuploadsection = () =>{
    const pdfuploaddiv=document.getElementById("pdfuploaddiv");
    if(pdfuploaddiv){
      pdfuploaddiv.scrollIntoView({behavior:"smooth"});
    }
  };
  return (
    <div className="pl-36 pr-36 pt-16 pb-16 flex items-center w-full max-lg:flex-col max-xl:px-8">
      <div className="flex w-full max-lg:flex-col justify-center items-center max-lg:text-center">
        <div className="flex flex-col w-full text-left  max-lg:text-center">
          <div className="">
            <h1 className="text-[4rem] max-lg:mb-6 font-bold text-CTAbuttonPink  flex flex-col max-xl:text-4xl xl:text-[3rem]">
            <span className="text-blue-900">Optimize Your </span>
              <span className="text-CTAbuttonPink">Resume for ATS and Hiring Leads</span>
            </h1>
            <p className="text-base tracking-wider mt-1 max-lg:text-[1rem] xl:text-sm font-semibold">
            Get instant feedback with our free AI checker, scanning 20+ crucial areas, designed and moderated by top recruiters to boost your job search effectively.

            </p>

            <div>
              <div className="mt-[1rem] flex gap-5 w-full items-center text-center pt-4 pb-4 tracking-wider capitalize max-lg:mt-[2rem]  max-lg:justify-center text-lg xl:text-sm">
                {/* <p className="text-[1.2rem] text-trans max-lg:text-[0.8rem]">
                  Enhance Your Resume's Potential with a Single Click!
                </p> */}

                

                <button
                  type="button"
                  className="pt-2 pb-2 pl-10 pr-10 text-lg  bg-CTAbuttonPink rounded-lg text-white tracking-wider border-none outline-none active:from-CTAbuttonPink hover:scale-105 focus:scale-105 transform transition-all duration-300 ease-in-out hover:shadow-lg"
                  onClick={scrolltopdfuploadsection}
                >
                  Get Your Resume Score
                </button>

                {/* <button className="p-4 font-medium text-white bg-CTAbuttonPink shadow-lg hover:bg-CTAbuttonPink focus:outline-none focus:ring-2 focus:ring-CTAbuttonPink focus:ring-offset-2 focus:ring-offset-pink-200 text-[1.2rem] tracking-wider max-lg:text-[0.9rem] max-lg:p-2 flex justify-center items-center text-center gap-2 max-lg:flex-col">
                  Get Your Resume Score
                </button> */}
              </div>

              <div>
                <div>
                  <h6 className=" text-gray-400 mt-8 xl:text-sm">
                    loved by interviewers at
                  </h6>
                </div>
                <div></div>
              </div>

              <div className="flex w-full flex-wrap gap-8 justify-left items-center max-lg:justify-center max-lg:gap-8 mt-2  max-lg:text-center mb-10">
                <img
                  loading="lazy"
                  className="  w-[70px]  mt-2"
                  src={Tesla}
                />
                <img
                  loading="lazy"
                  className=" w-[70px] mt-1"
                  src={Facebook}
                />
                <img
                  loading="lazy"
                  className="  w-[70px]"
                  src={Spotify}
                />
               
                <img
                  loading="lazy"
                  className="  w-[70px]"
                  src={Google}
                />
              </div>

              {/* <img
                loading="lazy"
                src={triangle}
                alt="Resume Scanner"
                className=""
              /> */}
            </div>
          </div>
        </div>

        <div className="flex w-full justify-end items-center  max-lg:justify-center">
          {/* <img
            loading="lazy"
            src={Image2}
            className="w-[600px] h-[500px] aspect-[0.52] max-lg:mt-10 max-lg:max-w-[450px] max-lg:max-h-[300px] xl:w-[500px] xl:h-[400px]"
            alt="hero bg"
          /> */}
          <FlipCarousel/>

      {/* <video className="w-[600px] h-[500px] aspect-[0.52] max-lg:mt-10 max-lg:max-w-[450px] max-lg:max-h-[300px] xl:w-[500px] xl:h-[400px] object-cover" autoPlay loop muted>
        <source src={videoFile}  />
        Your browser does not support the video tag.
      </video> */}
    </div>
      </div>
    </div>
  );
};

export default Hero;
