import { useState } from "react";
import DragAndDropInput from "./shared/DragAndDropInput";
import React from "react";

export const sectionStyles = "flex flex-col gap-5 p-4 w-full";
export const inputBorderStyles = "border border-gray-300 rounded-md";
export const labelStyles = "text-lg font-semibold";
export const inputStyles = "px-4 py-3";

interface JobApplicationState {
  fullName: string;
  email: string;
  message: string;
}

const JobApplication = () => {
  const [state, setState] = useState<JobApplicationState>({
    fullName: "",
    email: "",
    message: "",
  });

  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [coverLetterFile, setCoverLetterFile] = useState<File | null>(null);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="w-full flex flex-col gap-10 pt-10 px-[100px] xl:px-[250px]">
      <h1 className="text-4xl font-bold text-darkText">Job Application</h1>
      <div className="flex flex-col gap-5">
        <div className="flex gap-20">
          <section className={`${sectionStyles}`}>
            <label htmlFor="name" className={`${labelStyles}`}>
              Enter Full Name
            </label>
            <input
              type="text"
              name="fullName"
              value={state.fullName}
              onChange={handleInputChange}
              placeholder="Enter Your Fullname"
              className={`${inputBorderStyles} ${inputStyles}`}
            />
          </section>
          <section className={`${sectionStyles}`}>
            <label htmlFor="email" className={`${labelStyles}`}>
              Enter Email
            </label>
            <input
              type="Email"
              name="email"
              value={state.email}
              onChange={handleInputChange}
              placeholder="Enter Your Email"
              className={`${inputBorderStyles} ${inputStyles}`}
            />
          </section>
        </div>
        <div className="flex gap-20">
          <section className={`${sectionStyles}`}>
            <label htmlFor="resume" className={`${labelStyles}`}>
              Upload CV
            </label>
            <DragAndDropInput
              selectedFIle={resumeFile}
              setSelectedFile={setResumeFile}
            />
          </section>
          <section className={`${sectionStyles}`}>
            <label htmlFor="coverletter" className={`${labelStyles}`}>
              Upload <span>Cover Letter</span>
            </label>
            <DragAndDropInput
              selectedFIle={coverLetterFile}
              setSelectedFile={setCoverLetterFile}
            />
          </section>
        </div>

        <section className={`${sectionStyles}`}>
          <label htmlFor="message" className={`${labelStyles}`}>
            Write Your Message
          </label>
          <textarea
            name="message"
            value={state.message}
            onChange={handleInputChange}
            rows={5}
            placeholder="Write Your Message"
            className={`w-full ${inputBorderStyles} p-4`}
          />
        </section>
      </div>

      <button
        type="button"
        className="mx-auto bg-CTAbuttonGreen py-4 max-w-xs w-full text-xl font-bold text-background rounded-md"
      >
        Apply
      </button>
    </div>
  );
};

export default JobApplication;
