//import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../index.css"

const Carousel = () => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay:true,
  //   arrow:false,
  // };

  
  return (
    <div  className="w-full outline-none focus:outline-none focus:border-none">
      
      {/* <Slider {...settings} className="w-full h-full focus:outline-none focus:border-none"> */}
        <div className="flex-flex-col w-full h-[50%] focus:outline-none focus:border-none p-5">
          <p className="text-lg text-[#1c3c8c] font-semibold justify-between items-center h-1/2">Your resume score is determined by its format, how relevant the content is, and whether it includes all the necessary information. <span className="text-CTAbuttonPink">The upcoming report will give a detailed analysis of these factors and your overall resume score.</span></p>
          <img src="https://tools.camonk.com/static/media/1.d7944e4b.webp" alt="" className="w-full h-full border-none outline-none focusring" />
        </div>
        {/* <div className="w-full h-[50%] focus:outline-none focus:border-none">
          <img src="https://tools.camonk.com/static/media/1.d7944e4b.webp" alt="" className="w-full h-full border-none outline-none focusring" />
        </div>
        <div className="w-full h-[50%] focus:outline-none focus:border-none">
          <img src="https://d502jbuhuh9wk.cloudfront.net/articles/60bde0c20cf29b3408eff990/nQfBfonlineresumeconceptillustration1143605166.jpg" alt="" className="w-full h-full border-none outline-none focusring" />
        </div> */}
        
      {/* </Slider> */}
    </div>
  );
};
export default Carousel;
