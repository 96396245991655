import React , { useEffect } from "react";  ///
import Ats from "../components/HomeScreen/Ats";
import Optimization from "../components/HomeScreen/Optimization";
//import Check from "../components/HomeScreen/Check";
import Hero from "../components/HomeScreen/Hero";
import { PdfUpload } from "../components/HomeScreen/Pdfupload";
import Steps from "../components/HomeScreen/Steps";
import Faq from "../components/HomeScreen/Faq";
import { useNavigate } from "react-router-dom";
// import Privacy from "../components/HomeScreen/Privacy";

const HomeScreen: React.FC = () => {

  const navigate = useNavigate();

  const handleBackClick = () => {
    // Navigate to a specific path when the back button is clicked
    //console.log("back click")
    navigate('/');
  };

  useEffect(() => {
    sessionStorage.removeItem('alreadyRefreshed');
    if (!localStorage.getItem('hasReloaded')) {
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    } 

    window.addEventListener('popstate', handleBackClick);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="flex flex-col flex-wrap w-screen">
        <Hero/>
        <Ats/>
        <Optimization/>
        {/* <Check/>  */}
        <Steps/>
        <div className="lg:pl-36 lg:pr-36 lg:pt-16 lg:pb-16 p-4">

        <PdfUpload setIsModalOpen={() => {}}/>
        </div>

        <Faq/>

        {/* <Privacy/> */}
      </div>
    </>
  );
};

export default HomeScreen;
