import  { useState } from 'react';
import CommonAccordian from "./CommonAccordian";
import ResumeSection from "./IndividualSections/ResumeSection.";
import UserProfileBreif from "./IndividualSections/UserProfileBreif";
import { profileSections } from "./profileSectionsData";
//import CommonFunctionalButtons from "../shared/CommonFunctionalButtons"; React,
import  FileUpload from "./ProfileComponent/FileUpload";
import React from 'react';



const UserProfileSection = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileSelected = (file: File | null) => {
    setSelectedFile(file);
    // Additional logic to handle the file
  };
  
  console.log(selectedFile);

  const onSave = () => {
    const name = localStorage.getItem('name');
    const email = localStorage.getItem('email');
    const location = localStorage.getItem('location');
    const phonenumber = localStorage.getItem('phonenumber');
    const dateOfBirth = localStorage.getItem('dob');
    const gender = localStorage.getItem('gender');
    const currentDateTime = localStorage.getItem('updatedtimestamp');
    const address = localStorage.getItem('address');
    const headline = localStorage.getItem('headline');
    //const workStatus = localStorage.getItem('workstatus');
    const expectedSalary = localStorage.getItem('expectedsalary');


    // const data1 = {
    //   "uid": 89897,
    //   "name": name,
    //   "email_id": email,
    //   "location": location,
    //   "phoneno": phonenumber,
    //   "dob": dateOfBirth,
    //   "gender": gender,
    //   "updation_timestamp": currentDateTime,
    //   "address": address,
    //   "headline": headline,
    //   "workStatus": workStatus,
    //   "expected_salary": expectedSalary
    // }

    const data = {   //// here you can use this payload to post the request on axios 
        "uid": 10,  //// pass the unique id you are generating
        "name": name,
        "address": address,
        "dob": dateOfBirth,
        "education": "St francis",
        "email_id": email,
        "Emp_type": "contractual",
        "expected_salary": expectedSalary,
        "gender": gender,
        "Job_interested": false,
        "job_type": "full time",
        "phoneno": phonenumber,
        "resume_headline": headline,
        "updation_timestamp": currentDateTime,
        "work_exp": "NAN",
        "skills":[{
            "skill_name":"Developer",
            "rating":"3"   
        },
        {
            "skill_name":"Backend Developer",
            "rating":"4"   
        }],
        "work_location":[{
            "location":location
        
        },
        {
            "location":location
            
        }]
    }
    

    console.log('holadata ',data);

  }

  return (
    <div className="flex flex-col gap-10 my-16 ">
      <UserProfileBreif />

      <div className="flex flex-col gap-6">
        <ResumeSection />
        <FileUpload label="Upload your PDF" onFileSelected={handleFileSelected} />
        {profileSections.map((section) => (
          <CommonAccordian
            LogoComponent={section.LogoComponent}
            heading={section.heading}
            description={section.description}
            Component={section.Component}
          />
        ))}
      </div>
      <div>
      <div className="flex justify-center items-center gap-4">
        <button className="text-white bg-CTAbuttonPink py-3 px-8" onClick={onSave}>Save</button>
        <button className="border border-primary py-2 px-8">Cancel</button>
      </div>
      </div>
    </div>
    
  );
};

export default UserProfileSection;
