



import React from 'react';
import LengthBar from '../externalcomps/LengthBar';
import TableComponent from '../externalcomps/PaginationTable';


// Define an interface for the component props
interface PersonalProps {
  image?: string;
  page_no?: number;
  resume_type?: string;
  resume_length?: number;
  file_size?: number;
  yoe?: number;
}

const ExternalResume: React.FC<PersonalProps> = ({ image, page_no, resume_type, resume_length, file_size, yoe }) => {

  let status = "";

  console.log(`DKNCFDJF${page_no}NDJ`);
  console.log(`DKNCFD${yoe}JFNDJ`);
  //console.log(yoe);

  const tableData = [
    { years: "0 - 2.5", maxPages: "1.5", maxWords: "500" },
    { years: "2.5 - 5", maxPages: "2", maxWords: "700" },
    { years: "5-10", maxPages: "3", maxWords: "900" },
    { years: "10+", maxPages: "3.5", maxWords: "1100" }
];


  
  // const hashMap = {
  //   "singleRowResume": "Single Column",
  //   "mixedResume": "Block-Style",
  //   "twoColumnResume": "Two Column"
  // }

  if (resume_length >= 350 && resume_length <= 1000){
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status = "Optimal";
  }
  else if (resume_length < 350){
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status = "Less content compared to the generally accepted range.";
  }
  else{
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status = "More content compared to the generally accepted range.";
  }

  return (
    <div className="max-w-m rounded overflow-hidden shadow-lg max-lg:w-full bg-white animatediv">
      {/* {imageUrl && <img className="w-full" src={imageUrl} alt="Card image" />} */}
      
      <div className="py-10 px-10 text-sm max-lg:p-2 text-justify">
        {/* {title && <div className="font-bold text-xl mb-2">{title}</div>} */}
        <h1><strong>Image:</strong></h1>
        {(image)?(<p>While you've indicated the resume contains an image, it's generally advised not to include one to avoid any potential bias.</p>)
        :(<p>While you've indicated the resume does not contains an image, it's generally advised not to include one to avoid any potential bias.</p>)}
        <br/>
        <h1><strong>Resume Pagination Based on Professional Experience:</strong></h1>
        <p>Please refer the following table and if required please optimize your resume.</p>   
        <br/>
        <TableComponent tableData = {tableData}/>
        <br/>
        <h1><strong>Resume Format:</strong></h1>
        
        {(resume_type === "Single Row Resume" || resume_type === "Mixed Resume")?(<p>The selected resume template is well-suited for ATS compatibility.</p>)
        :(<p>Multi-column resumes often do not fare well with ATS systems. Therefore, it's typically advised to opt for a single-column format or use multiple block resumes instead. I would recommend considering a change to a single-column format or utilizing a multi-block resume for better compatibility with ATS systems.</p>)}
        <br/>
        <h1><strong>Resume Length:</strong></h1>
 
        <p className='pb-3'>Your resume has {resume_length} words(counted from the revised section) which is <strong className={`${(resume_length >= 350 && resume_length <= 1000)?'text-green-600':`${(resume_length < 350)?'text-red-800':'text-yellow-800'}`}`}>{status}</strong></p>
        <LengthBar value={(resume_length/20)}/>
        <br/>
        <h1><strong>File Size:</strong></h1>
        {(file_size >= 0.80) && (<p>The PDF file size is <span className='text-red-600 font-semibold'>{file_size*1000}kb</span> which slightly greater than the ideal range of 800kb please try to reduce it to make it more ATS friendly.</p>)}
        
        {(file_size < 0.80) && (<p>The PDF file size is <span className='text-green-600 font-semibold'>{file_size*1000}kb</span> which is below the maximum accepted range of 800Kb, which is excellent.</p>)}
       
      </div>
    </div>
  );
};


export default ExternalResume;