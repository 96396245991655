// import React from "react";
import { FaArrowRight } from "react-icons/fa";
import apna from "../images/apna.jpeg";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { BsArrowLeft, BsGoogle, BsLinkedin } from "react-icons/bs";
import { useState } from "react";
import Axios from "axios";
import React from "react";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const googleSignup = async () => {
    window.open('http://localhost:3001/user/google-signup/callback', "_self")
  };
  const linkdinSignup = async () => {
    console.log("Linkdein signup");
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailchecker = (em: any) => {
    const emailcheck = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i;
    if (emailcheck.test(em)) {
      return true;
    } else {
      window.alert("invailed email");
      return false;
    }
  };

  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validate = async (em: any) => {
    emailchecker(em);

    if (emailchecker(em) ) {
      const data = {
        email: `${email}`,
        password: `${password}`,
      };
      await Axios.post("http://localhost:3001/user/login", data, {
        headers: {
          "Content-Type": "application/json",
        
        },
      })
        .then(function (response) {
          console.log(response.data, "PPPPPPPPPPPPPPPPPPPPPPPPPPP");
          localStorage.setItem("accesstoken", `${response.data.accesstoken}`);
          // Navigate('/Dashboard')
          return response.data;
        })
        .catch((err) => {
          console.log(err, "error in sending");
          window.alert(err);
          return Promise.reject(err);
        });
    }
    return;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handelform = async (e: any) => {
    e.preventDefault();
    validate(email);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className=" bg-gray-50 h-screen">
      <div className="flex gap-5 w-screen h-screen max-md:flex-col">
        <div className="flex flex-col text-center justify-center items-center h-full lg:w-4/12 xl:w-5/12 max-md:ml-0  max-lg:w-full max-lg:h-auto">
          <div className="p-4 flex text-center justify-center flex-col items-center w-full h-full bg-gradient-to-b from-linearlight via-linearmid to-lineardark max-md:h-auto">
            <img
              loading="lazy"
              src={apna}
              className="w-[250px] h-[250px] aspect-[0.52] max-md:mt-5 max-md:max-w-[250px]"
              alt="apna"
            />
            <div className="mt-8 text-[40px] max-lg:text-[30px] font-bold leading-10 text-center  text-white">
            Access Your Account
            </div>
            <div className="mt-3 text-[20px] max-lg:text-[18px] leading-10 text-center text-white">
            Login to your account and explore exciting Opportunities
            </div>
          </div>
        </div>
        <div className="p-8 flex flex-col ml-5 lg:w-7/12 max-md:ml-0 max-lg:w-full max-md:pt-0">
          <div className="flex justify-between mt-0 text-sm text-neutral-700  max-md:mt-0 max-sm:hidden">
            <div className="flex text-center items-center gap-1 cursor-pointer">
              <BsArrowLeft className="text-[1.5rem] " />
              <span className="">Return Home</span>
            </div>
            <div className="flex-shrink">
              <span className="text-neutral-700">Not a member yet?</span>
              <span className="font-bold ml-1 underline text-red-500 cursor-pointer">
              JOIN NOW
              </span>
            </div>
          </div>
          <div className="mt-1 max-md:max-w-full">
            <div className="flex gap-5 text-center items-center justify-center max-md:flex-col max-md:gap-0">
              <div className="flex flex-col xl:w-[50%] lg:w-[80%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow pb-2.5 mt-12 2xl:mt-20 max-md:mt-10">
                  <div className="self-center text-2xl font-bold leading-10 text-center uppercase text-primary">
                  Welcome Back!
                  </div>
                  <div className="self-center mt-1 2xl:mt-3 text-base leading-7 text-center text-neutral-700">
                  Login to continue
                  </div>
                  <button
                    className="cursor-pointer flex gap-5 justify-between self-center px-10 py-4 mt-2 2xl:mt-6 max-w-full text-sm leading-6 text-center rounded-lg border border-solid bg-neutral-50 border-zinc-100 text-zinc-600 w-[380px] max-md:px-5 shadow-md hover:shadow-sm"
                    onClick={googleSignup}
                  >
                    <BsGoogle className="text-[1.5rem]" />
                    <div className="flex-auto text-[1rem]">
                      Sign up with Google
                    </div>
                  </button>
                  <button
                    className="cursor-pointer flex gap-5 justify-between self-center px-10 py-4 mt-3 max-w-full text-sm leading-6 text-center rounded-lg border border-solid bg-neutral-50 border-zinc-100 text-zinc-600 w-[380px] max-md:px-5 shadow-md hover:shadow-sm"
                    onClick={linkdinSignup}
                  >
                    <BsLinkedin className="text-[1.5rem]" />
                    <div className="flex-auto text-[1rem]">
                      Sign up with LinkedIn
                    </div>
                  </button>
                  <div className="justify-center self-center px-5 py-1.5 mt-1 2xl:mt-4 text-sm font-bold text-center whitespace-nowrap bg-neutral-50 text-neutral-800">
                    Or
                  </div>
                  <form
                    encType="multipart/form-data"
                    onSubmit={handelform}
                    className="flex flex-col justify-center "
                  >
                    
                    <div className="flex gap-4 flex-wrap px-5 py-2.5 mt-3 text-base whitespace-nowrap bg-white border border-solid border-neutral-500 text-neutral-700">
                      <FaEnvelope className="self-center" />
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        className="flex-auto my-auto outline-none h-[1.5rem] 2xl:h-[2rem]"
                        placeholder="doe@gmail.com"
                      />
                    </div>
                    <div className="flex gap-5 flex-wrap justify-between px-5 py-2.5 mt-3 w-full bg-white border border-solid border-neutral-500">
                      <FaLock className="self-center text-neutral-700" />
                      <div className="flex gap-4 flex-auto leading-7 items-center max-sm:flex-wrap">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          className="flex-auto my-auto outline-none h-[1.5rem] 2xl:h-[2rem]"
                          placeholder="password"
                        />
                        <div
                          className="text-xs cursor-pointer text-right text-neutral-400 flex items-center"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <FaEyeSlash className="text-xl" />
                          ) : (
                            <FaEye className="text-xl" />
                          )}
                        </div>
                      </div>
                    </div>
                    
                    
                    <button
                      type="submit"
                      className="flex items-center gap-5 justify-between px-9 py-5 mt-5 text-base font-medium leading-7 text-white bg-slate-800 max-md:px-5 cursor-pointer shadow-xl hover:shadow-md "
                     
                    >
                      <div className="">PROCEED TO MY ACCOUNT</div>
                      <FaArrowRight className=""/>
                    </button>
                  </form>
                  <div className="flex-shrink mt-1 hidden max-sm:block">
                    <span className="text-neutral-700">Already a Member?</span>
                    <span className="font-bold underline text-red-500">
                      {" "}
                      LOG IN NOW
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
