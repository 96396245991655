



import React from 'react';
//import ReactMarkdown from 'react-markdown';
import TextProcessor from './TextProcessor';


// Define an interface for the component props
interface RRprop {
  content?: string;
  job_position? : string;
  yoe? : string;
  
}

const RelevantResume: React.FC<RRprop> = ({ content}) => {
  return (
    <div className="max-w-m rounded-xl overflow-hidden shadow-lg bg-white animatediv">
      <p className="px-4 py-4 text-sm font-semibold">(Here AIMO, will read the resume and compare it with other resumes provided in training data for the same experience and role, if the answer is negative/irrelevant, follow the tips provided by the model, else the frequency of receiving calls from HR recruiter will be low.)</p>
      {/* {imageUrl && <img className="w-full" src={imageUrl} alt="Card image" />} */}
      <div className="px-10 py-4 text-sm max-lg:text-left text-justify max-lg:px-2">
       
        <TextProcessor text = {content}/>
       
      </div> 
    </div>
  );
};


export default RelevantResume;