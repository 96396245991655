import React from "react";
import { FaRegFilePdf } from "react-icons/fa6";

const ResumeSection = () => {
  return (
    <>
      <div className="flex items-center container max-w-5xl gap-4 mx-auto p-4 rounded-md bg-secondary shadow-md cursor-pointer">
        {<FaRegFilePdf size={40} color="#0F0C29" />}
        <div className="text-primary">
          <h1 className="font-semibold text-lg">Resume-Dhanasai Tholeti</h1>
          <p>Added on March 4th 20243</p>
        </div>
      </div>
    </>
  );
};

export default ResumeSection;
