import React, { useEffect, useState } from 'react';
import './externalcomps/FlipCarousel.css'; // Import custom styles
import Image1 from '../../images/fp.png';
import Image2 from '../../images/fp1.png';
import Image3 from '../../images/fp2.png';

const images = [
    Image1,
    Image2,
    Image3,
];

function FlipCarousel() {
    const [index, setIndex] = useState(0);
    const [flipping, setFlipping] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setFlipping(true);
            setTimeout(() => {
                setIndex(prev => (prev + 1) % images.length);
                setFlipping(false);
            }, 600); // This should match the flip transition duration
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative">
            <div className="w-full h-full mx-auto perspective">
                <div className={`flip-card ${flipping ? 'flipping' : ''} overflow-hidden w-full h-full`}>
                    <img src={images[index]} className="w-full h-full object-cover backface-hidden" alt="Flip Image"/>
                </div>
            </div>
        </div>
    );
}

export default FlipCarousel;