



import React, { useState } from 'react';
///import ReactMarkdown from 'react-markdown';
import TextProcessor from './TextProcessor';
//import parse from 'html-react-parser';



// Define an interface for the component props
interface FRprop {
  content?: string;
  textract?: string;

}



const FixedResume: React.FC<FRprop> = ({ content, textract }) => {
  //const [reactContent, setMarkdownSource] = useRemark();

  const [activeTab, setActiveTab] = useState('tab2');







  return (
    <div className='px-10  max-lg:px-0 max-lg:w-full'>


      <div className="flex p-2 space-x-1">
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium text-white rounded-lg
                     ${activeTab === 'tab1' ? 'bg-CTAbuttonPink' : 'bg-gray-700 hover:bg-CTAbuttonPink'}`}
          onClick={() => setActiveTab('tab1')}
        >
          ATS Extracted resume
        </button>
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium text-white rounded-lg
                     ${activeTab === 'tab2' ? 'bg-CTAbuttonPink' : 'bg-gray-700 hover:bg-CTAbuttonPink'}`}
          onClick={() => setActiveTab('tab2')}
        >
          Fixed Resume
        </button>
      </div>

      <h1 className='p-3 text-sm font-semibold'>Note: ATS resume parsers may miss some information from multi-column resumes. Hence see for information loss while analyzing the resume.</h1>

      <div className='flex w-full overflow-hidden flex-wrap max-lg:p-2 p-6 rounded-lg bg-white mb-3 animatediv'>
        {activeTab === 'tab1' ? (

          <div className="w-full flex flex-col flex-wrap">

            <p className=' py-2 w-full flex flex-wrap text-base font-semibold'>This is how the ATS has read your resume:</p>

            <div className=" py-4 text-sm w-full flex flex-wrap">

              {textract.split('\n').map((item, idx) => {
                return (

                  <span key={idx} className='w-full flex flex-wrap text-sm'>

                    {item}

                    <br />
                  </span>

                  // eslint-disable-next-line no-irregular-whitespace
                );
              })}


            </div>
          </div>

        ) : (


          <div className="w-full flex flex-wrap flex-col">
            {/* {imageUrl && <img className="w-full" src={imageUrl} alt="Card image" />} */}

            <h4 className="w-full flex flex-wrap py-4 text-sm font-semibold">Please review the updated version of the resume, addressing any grammatical errors, repeated verbs,
              and spelling mistakes, while enhancing readability and engagement.
              The goal is to maintain a natural language tone, ensuring it doesn't appear as it was generated by AI.</h4>


            <div className="w-full flex flex-wrap py-5 text-sm">

              <TextProcessor text={content} />

              {/* {content} */}
              {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}




              {/* <DummyHtml/> */}

            </div>

          </div>


        )}
      </div>

      

    </div>
  );
};


export default FixedResume;
