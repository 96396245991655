import React from "react";
import RootLayout from "./Layout";
import RouteManager from "./routes/RouteManager";

const App = () => {
  return (
    <>
    
      <RootLayout>
        <RouteManager />
      </RootLayout>
    </>
  );
};

export default App;
