import React, { useState, useRef, useEffect } from "react";
import { BiSave } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import Modal from "./ResumeModal";
import Select from 'react-select';


interface JobInfo {
    name: string;
    yoe: string;
    jobdesc: string;
    jobname: string;
    Image: string;
    company: string;
  }



interface DesignationProps {
  resume?: string;
  setIsModalOpenProp: (value: boolean) => void;
  pagenum?: number;
  wordlength?: number;
  filesize? : number;
}

const Designations: React.FC<DesignationProps> = ({ resume, setIsModalOpenProp, pagenum, wordlength, filesize }) => {
  const [inputValue, setInputValue] = useState('');
  const [personalInfo, setPersonalInfo] = useState<JobInfo>({
    name: "",
    yoe: "0",
    jobdesc: "Student",
    jobname: "Software Developer/Engineer",
    Image: "No",
    company: "",
  });

  const [isField, setIsField] = useState(false);

  const prevCountRef = useRef('');

  const techJobs = [
    { value: "Software Developer/Engineer", label: "Software Developer/Engineer" },
    { value: "Mobile App Developer", label: "Mobile App Developer" },
    { value: "Front End Developer", label: "Front End Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Full-stack Developer", label: "Full-stack Developer" },
    { value: "Devops Engineer", label: "Devops Engineer" },
    { value: "Security Engineer", label: "Security Engineer" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Data Engineer", label: "Data Engineer" },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "Network Engineer/Administrator", label: "Network Engineer/Administrator" },
    { value: "Systems Administrator", label: "Systems Administrator" },
    { value: "Information Security Analyst", label: "Information Security Analyst" },
    { value: "Cloud Engineer", label: "Cloud Engineer" },
    { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
    { value: "AI Research Scientist", label: "AI Research Scientist" },
    { value: "Robotics Engineer", label: "Robotics Engineer" },
    { value: "Technical Support Specialist", label: "Technical Support Specialist" },
    { value: "Help Desk Technician", label: "Help Desk Technician" },
    { value: "UI/UX Designer", label: "UI/UX Designer" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Graphic Designer", label: "Graphic Designer" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Blockchain Developer", label: "Blockchain Developer" },
    { value: "Cybersecurity Specialist", label: "Cybersecurity Specialist" },
    { value: "Game Developer", label: "Game Developer" },
    { value: "VR/AR Developer", label: "VR/AR Developer" },
    { value: "Technical Writer", label: "Technical Writer" },
    { value: "Sales Engineer", label: "Sales Engineer" },
    { value: "Analyst", label: "Analyst" },
    { value: "Associate Consultant", label: "Associate Consultant" },
    { value: "Senior Consultant", label: "Senior Consultant" },
    { value: "Manager", label: "Manager" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Subject Matter Expert", label: "Subject Matter Expert" },
    { value: "Senior Manager", label: "Senior Manager" },
    { value: "Quality Assurance Engineer (QA)", label: "Quality Assurance Engineer (QA)" },

    { value: "SAP Consultant", label: "SAP Consultant" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "Solution Architect", label: "Solution Architect" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Process Integration Consultant", label: "Process Integration Consultant" },
    { value: "Change Management Specialist", label: "Change Management Specialist" },
    { value: "Compliance Officer", label: "Compliance Officer" },
    { value: "Training and Development Specialist", label: "Training and Development Specialist" },
    { value: "SAP Developer/Programmer", label: "SAP Developer/Programmer" },
    { value: "System Administrator", label: "System Administrator" },
    { value: "SAP Basis Administrator", label: "SAP Basis Administrator" },
    { value: "Security Consultant", label: "Security Consultant" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Quality Assurance Specialist", label: "Quality Assurance Specialist" },
    { value: "Data Migration Specialist", label: "Data Migration Specialist" },
    { value: "SAP HANA Consultant", label: "SAP HANA Consultant" },
    { value: "Application Support Specialist", label: "Application Support Specialist" },
    { value: "Help Desk/Technical Support", label: "Help Desk/Technical Support" },
    { value: "Maintenance Engineer", label: "Maintenance Engineer" },
    { value: "System Analyst", label: "System Analyst" },
    { value: "Enterprise Architect", label: "Enterprise Architect" },
    { value: "Business Process Owner", label: "Business Process Owner" },
    { value: "CIO/CTO for SAP-related technologies", label: "CIO/CTO for SAP-related technologies" },
    { value: "SAP Implementation Specialist", label: "SAP Implementation Specialist" }

];



  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  //const [enable, setenable] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

 

  

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };
  const handleModalClose = (chosenImage: string) => {
    if (chosenImage) {
      const filename = chosenImage.split('/').pop()?.split('.')[0] || '';
  
      // console.log("Selected resume image:", filename);

      // console.log("prev count ", prevCountRef.current);
      // console.log("personalInfo ", personalInfo.jobname);
      navigate('/loading_page', { state: { myData: {
        Yoe: personalInfo.yoe,
        JobRole : (personalInfo.jobname)?personalInfo.jobname:prevCountRef.current,
        JobDesc: personalInfo.jobdesc,
        JobStatus: true,
        resume: resume,
        resumetype: filename,
        image: (personalInfo.Image) == "Yes" ? true: false,
        pageno: pagenum,
        resumelength: wordlength,
        filesize: filesize,
        CompanyName:personalInfo.company
        } } });   
    }
    // Close the modal in both cases
    setIsModalOpen(false);
    setIsModalOpenProp(false);
  };
  

  const handleInputChange = (field: keyof JobInfo, value: string) => {

    // console.log('ohh ',value);
    // console.log('field ',field);
    setPersonalInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    
    
    if(field === "jobdesc"){
      setIsField(true)
    }

    if(field === "jobdesc" && value == "Student"){
      setIsField(false)
    }

    // if(field === "yoe"  &&  Number(value) < -1){
    //   console.log("hhss");
    //   setPersonalInfo({
    //     name: personalInfo.name,
    //     yoe: "0",
    //     jobdesc: personalInfo.jobdesc,
    //     jobname: personalInfo.jobname,
    //     Image: personalInfo.Image,
    //     company: personalInfo.company
    //   }
    // );
    // }
  };
  

  
  
  // const handleDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setPersonalInfo((prevState) => ({
  //     ...prevState,
  //     dropdownValue: e.target.value,
  //   }));
  // };

  const reports = () => {
    openModal();
    // console.log('role');
     //console.log(cleanresume);
    // eslint-disable-next-line prefer-const
    // let data =  {
    //   employedetail: employedetails,
    //   Yoe: number,
    //   JobRole : role,
    //   JobStatus: selectedValue
    //   };

    //   console.log(data);
    //   ///setemployedetails(passingval)
    
  
      // navigate('/loading_page', { state: { myData: {
      //   Yoe: personalInfo.yoe,
      //   JobRole : personalInfo.jobname,
      //   JobStatus: true,
      //   resume: resume,
      //   image: (personalInfo.Image) == "Yes" ? true: false
      //   } } });    
      
      // console.log('xone');
      // setIsModalOpen(false);

  }

  

  // ------------------------------ *********************************************** -------------------------------

  

  const handleInputChange1 = (newValue) => {
    
    setInputValue(newValue);

    personalInfo.jobname = newValue
    console.log('Input Changed to:', newValue);
    // Additional handling here if necessary
  };

  const handleNoOptionsMessage = ({ inputValue }) => {
    return inputValue ? `Go with the job field "${inputValue}"` : "No options available";
  };

  useEffect(() => {
    if(personalInfo.jobname){
      prevCountRef.current = null; // Store the current state in ref after each update
    }
    if(inputValue){
      prevCountRef.current = inputValue; // Store the current state in ref after each update
    }
    
  }, [inputValue,personalInfo.jobname]); // Only re-run if count changes


return (
  <>
    <div className="flex flex-col rounded-lg border border-dashed border-gray-900 bg-zinc-100 gap-y-2 px-4">
       <div className="mt-4 w-full p-4 border border-gray-300 rounded-lg">
      
        <div className="flex flex-col w-full justify-start items-start">
        <p className="text-sm font-semibold mb-2">Are you a?</p>
        <div className="flex flex-row items-center">
        <label className="flex items-center mr-6">
        <input
          type="radio"
          name="employmentType"
          value="Student"
          checked={personalInfo.jobdesc === "Student"}
          onChange={(e) => handleInputChange("jobdesc", e.target.value)}
          className="mr-2 h-4 w-4 text-[#4F46E5] focus:ring-[#4F46E5] border-gray-300 rounded"
        />
        <span className="text-sm">Student</span>
      </label>
      
      <label className="flex items-center">
        <input
          type="radio"
          name="employmentType"
          value="Professional"
          checked={personalInfo.jobdesc === "Professional"}
          onChange={(e) => handleInputChange("jobdesc", e.target.value)}
          className="mr-2 h-4 w-4 text-[#4F46E5] focus:ring-[#4F46E5] border-gray-300 rounded"
        />
        <span className="text-sm">Professional</span>
      </label>
    </div>
  </div>
</div>

      

      <div className="w-full p-4 border border-gray-300 rounded-lg">
      <label htmlFor="imageType" className="text-sm font-semibold mb-2">Does your Resume have an image?</label>
        <div className="flex items-center">
          <input
            id="yesImage"
            type="radio"
            name="ImageType"
            value="Yes"
            checked={personalInfo.Image === "Yes"}
            onChange={(e) => handleInputChange("Image", e.target.value)}
            className="mr-2 h-4 w-4 text-[#4F46E5] focus:ring-[#4F46E5] border-gray-300 rounded"
          />
         <label htmlFor="yesImage" className="text-sm mr-6 cursor-pointer">Yes</label>
          <input
            id="noImage"
            type="radio"
            name="ImageType"
            value="No"
            checked={personalInfo.Image === "No"}
            onChange={(e) => handleInputChange("Image", e.target.value)}
            className="mr-2 h-4 w-4 text-[#4F46E5] focus:ring-[#4F46E5] border-gray-300 rounded"
          />
          <label htmlFor="noImage" className="text-sm cursor-pointer">No</label>
        </div>
      </div>

      <div className="w-full p-2 border border-gray-300 rounded-lg">
        <label htmlFor="jobProfile" className="text-sm font-semibold mb-2 block">Position You Wish to Apply For</label>
       
        <Select
          options={techJobs}
          value={{ value: (personalInfo.jobname)?personalInfo.jobname:prevCountRef.current, label: (personalInfo.jobname)?personalInfo.jobname:prevCountRef.current}}
          onChange={(selectedOption) => handleInputChange("jobname", selectedOption.value)}
          className="w-full h-9 text-sm"
          noOptionsMessage={handleNoOptionsMessage}
          onInputChange={handleInputChange1}
          styles={{
            control: (provided) => ({
              ...provided,
              borderColor: "#718096",
              borderRadius: "0.5rem"
            })
          }}
        />
      </div>

      {isField && <div className="w-full p-4 border border-gray-300 rounded-lg">
        <label htmlFor="yearsOfExperience" className="text-sm font-semibold block">Years of Experience:</label>
        <input
          id="yearsOfExperience"
          type="number"
          placeholder="0"
          value={(Number(personalInfo.yoe) < 0 || (personalInfo.jobdesc == "Student"))?"0":(personalInfo.yoe)}
          disabled = {(personalInfo.jobdesc == "Student")?true:false}
          onChange={(e) => handleInputChange("yoe", e.target.value)}
          className="pl-2 h-8 w-full border border-gray-300 rounded-md focus:outline-none"
          style={{ fontSize: " 0.875rem" }}
        />
      </div>}

      {isField && <div className="w-full p-2 border border-gray-300 rounded-lg ">
        <label htmlFor="yearsOfExperience" className="text-sm font-semibold block">Company you're applying to (cover letter)</label>
        <input
          id="company"
          type="text"
          placeholder="eg: TCS, Wipro etc."
          value={personalInfo.company}
          //disabled = {enable}
          onChange={(e) => handleInputChange("company", e.target.value)}
          className="pl-2 w-full border border-gray-300 rounded-md focus:outline-none"
          style={{ fontSize: " 0.875rem" }}
        />
      </div>}

      <div className="flex justify-center items-center gap-4">
        <button
          className="p-2 mb-2 font-medium text-white bg-CTAbuttonPink shadow-lg hover:bg-CTAbuttonPink focus:outline-none focus:ring-2 focus:ring-CTAbuttonPink focus:ring-offset-2 focus:ring-offset-pink-200 text-sm tracking-wide max-lg:text-sm max-lg:p-2 flex justify-center items-center text-center gap-2"
          onClick={reports}
        >
          Save <BiSave className="inline-block" size={20} />
        </button>
        {isModalOpen && <Modal onClose={handleModalClose} />}
      </div>
    </div>
  </>
);



};

export default Designations;